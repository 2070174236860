import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import * as UserAction from '../../../../Store/Actions/User';
import Header from '../../../../Components/Header';
import {
  IN_PROGRESS,
  ERROR,
  LOGOUT,
  SUCCESS_MESSAGE,
  NEW_USER,
} from '../../../../Constants';
import { clearAccessToken } from '../../../../Utils';
import './AddUser.scss';
import { FooterMenu } from '../../../../Components/FooterMenu';
import { defaultLabels } from '../../../../Utils/label.utils';

export const AgentAddUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [labels, setLabels] = useState(defaultLabels.filter(l => l !== 'dormant'));
  const [userDetail, setUserDetail] = useState({
    name: '',
    displayName: '',
    details: '',
    password: '',
    confirmPassword: '',
    labels: ['new'],
  });

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...userDetail };
    commonData[field] = event.target.value;
    return setUserDetail(commonData);
  };

  const addUser = () => {
    dispatch({ type: IN_PROGRESS });
    if (
      !userDetail.name ||
      !userDetail.displayName ||
      !userDetail.password ||
      userDetail.name === '' ||
      userDetail.password === ''
    ) {
      return dispatch({
        type: ERROR,
        data: { error_msg: t('error.allRequired') },
      });
    }

    if (userDetail.password !== userDetail.confirmPassword)
      return dispatch({
        type: ERROR,
        data: { error_msg: t('error.passNotMatch') },
      });

    UserAction.addUser(userDetail)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({ type: SUCCESS_MESSAGE, data: { message: null } });
          dispatch({ type: NEW_USER, data: {user: response.data.user} });
          navigate('/Agent/Users');
          toast.success(t('message.userSaved'));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 403) {
            clearAccessToken();
            dispatch({
              type: LOGOUT,
            });
          } else
            dispatch({
              type: ERROR,
              data: { error_msg: error.response.data.message },
            });
        } else {
          dispatch({
            type: ERROR,
            data: { error_msg: error.message.toString() },
          });
        }
      });
  };


  const toggleLabel = (label: string) => {
    const commonData = { ...userDetail };
    if (commonData.labels.includes(label)) {
      commonData.labels.splice(commonData.labels.indexOf(label), 1);
    } else {
      commonData.labels.push(label);
      commonData.labels.sort((a, b) => a.localeCompare(b));
    }
    setUserDetail(commonData);
    setLabels([...labels]);
  };

  return (
    <div className='agent_layout add_user'>
      <Header name={t('agent.addPlayer')} agentMenu={true} />
      <div className='agent_body'>
        <ValidatorForm
          className='input_form_box'
          onSubmit={() => addUser()}
          autoComplete='off'
        >
          <div className='input_form_box_inner'>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                placeholder={t('agent.addPlayer.username')}
                autoComplete='off'
                size='small'
                type='text'
                className='input_box'
                name='name'
                value={userDetail.name}
                onChange={handleChange}
              />
            </div>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                placeholder={t('user.displayName')}
                autoComplete='off'
                size='small'
                type='text'
                className='input_box'
                name='displayName'
                value={userDetail.displayName}
                onChange={handleChange}
              />
            </div>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                placeholder={t('agent.addPlayer.password')}
                autoComplete='off'
                size='small'
                type='password'
                className='input_box'
                name='password'
                value={userDetail.password}
                onChange={handleChange}
              />
            </div>
            <div className='input_form'>
              <TextValidator
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                placeholder={t('agent.addPlayer.passwordConfirm')}
                autoComplete='off'
                size='small'
                type='password'
                className='input_box'
                value={userDetail.confirmPassword}
                name='confirmPassword'
                onChange={handleChange}
              />
            </div>
            <div className='input_form'>
              <TextValidator
                className='input_box'
                name='details'
                placeholder={t('agent.addPlayer.details')}
                value={userDetail.details}
                type='text'
                validators={['required']}
                errorMessages={[t('error.fieldRequired')]}
                variant='outlined'
                size='small'
                multiline
                rows={3}
                onChange={handleChange}
              />
            </div>
            <div className="title">{t('user.label')}</div>
            <div className='row_details_text label-toggle'>
              {labels.map(label => (
                <button
                  key={label} className={'label ' + (userDetail.labels.includes(label) ? 'selected' : '')}
                  type='button'
                  onClick={() => toggleLabel(label)}
                >
                  {t('labels.' + label)} {userDetail.labels.includes(label) && <i className='fa-solid fa-close'></i>}
                </button>
              ))}
            </div>
          </div>
          <div className='form_btn'>
            <Button type='submit' variant='contained' className='submit_btn'>
              {t('label.submit')}
            </Button>
          </div>
        </ValidatorForm>
      </div>
      <FooterMenu/>
    </div>
  );
};
