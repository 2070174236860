import React from 'react';

import { FooterMenu } from '../../../Components/FooterMenu';

import './Transaction.scss';
import { TransactionDetails } from '../../../Components/Admin/TransactionDetails';

const ManagerTransactionDetails = () => {
  return (
    <div className='agent_layout'>
      <TransactionDetails />
      <FooterMenu isManager={true} />
    </div>
  );
};

export default ManagerTransactionDetails;
