import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './MessageList.scss';
import { useNavigate } from 'react-router-dom';
import { useGameReducer } from '../../Store';
import { UserActions } from '../../Store/Actions';

export const MessageNew = () => {
  const gameReducer = useGameReducer();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (gameReducer.users.length > 0) {
      setUsers([...gameReducer.users]);
    }
  }, [gameReducer.users]);
  useEffect(() => {
    UserActions.getUsers();
  }, []);

  const handleSubmit = (e) => {
    setError(null);
    if (user && subject && message) {
      UserActions.sendMessage(user, subject, message).then(() => {
        navigate('../');
      });
    } else {
      setError('Invalid form');
    }
    e.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='input_form_box'
      autoComplete='off'
    >
      <div className='input_form_box_inner'>
        <div className='input_form msg_input flex-row'>
          <span className='input_box_text w_120 justify-content-start black'>{t('messages.to')}</span>
          <select className='input_box' onChange={e => setUser(e.target.value)}>
            <option></option>
            {users?.length > 0 && users?.map((user, i) => (
              <option value={user.user.id} key={i}>{user.user.name}</option>
            ))}
          </select>
        </div>
        <div className='input_form msg_input flex-row mt_5'>
          <span className='input_box_text w_120 justify-content-start black'>{t('messages.subject')}</span>
          <input onChange={e => setSubject(e.target.value)}/>
        </div>
        <div className='input_form mt_5 stretch-details'>
          <span className='input_box_text w_120 justify-content-start black'>{t('messages.message')}</span>
          <textarea onChange={e => setMessage(e.target.value)}></textarea>
        </div>

        {error && (
          <div className="mt_5 form_error">All field required</div>
        )}

      </div>
      <div className='form_btn'>
        <Button type='submit' variant='contained' className='submit_btn'>
          {t('label.submit')}
        </Button>
      </div>
    </form>
  );
};
