import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';

import { useGameReducer } from '../../Store';
import { DATE_FORMAT, TIME_FORMAT } from '../../environment.config';
import { InputAdornment, TextField } from '@material-ui/core';
import { UserActions } from '../../Store/Actions';

export const TransactionList = () => {
  const navigate = useNavigate();
  const [users, setTransactions] = useState([]);
  const { t } = useTranslation();
  const [search, setSearch] = useState({
    search: '',
  });

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.userDetail) {
      UserActions.getUsers();
      UserActions.getUserTransactions();
    }
  }, [gameReducer.userDetail]);

  useEffect(() => {
    let transactions = [];
    if (gameReducer.creditTransactions) {
      transactions = gameReducer.creditTransactions.map((transaction) => ({
        ...transaction,
        user: gameReducer.users.find(u => u.user.id === transaction.accountId),
        created: moment(transaction.created).format(DATE_FORMAT),
        createdTime: moment(transaction.created).format(TIME_FORMAT),
      }))
        .filter(v => !search || v.correspondingUsername.toLowerCase().includes(search.search.toLowerCase()) || v.labels.find(c => c.toLowerCase().includes(search.search.toLowerCase())))
      ;

    }
    if (transactions) {
      const groupDate = _.chain(transactions)
        .groupBy('created')
        .map((value, key) => ({ created: key, transactions: value }))
        .value();

      setTransactions(groupDate);
    }
  }, [gameReducer.creditTransactions, search]);

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...search };
    commonData[field] = event.target.value;
    return setSearch(commonData);
  };

  const open = (id: string) => {
    navigate(id);
  };


  return (
    <div className='agent_body full_width'>

      <div className='search_box'>
        <TextField
          variant='outlined'
          size='small'
          type='text'
          name='search'
          className='search_box_inner'
          placeholder={t('label.search')}
          value={search.search}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon/>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className='full_table button_rows' id='transaction_table'>
        <div className='table_header'>
          <span className='table_header_text left'>{t('transactions.time')}</span>
          <span className='table_header_text'>{t('transactions.account')}</span>
          <span className='table_header_text'>{t('transactions.amount')}</span>
        </div>
        <div className='table_body_main'>
          {users.map((singlegroup, id) => (
            <div className='table_body_inner' key={id}>
              <div className='table_body_section'>
                <span className='section_title'>{singlegroup.created}</span>
              </div>
              <div className='table_body'>
                {singlegroup.transactions.map((singleuser, id) => (
                  <div className='table_body_list' key={id} onClick={() => open(singleuser.id)}>
                    <span className='body_list left'>
                      {singleuser.createdTime}
                    </span>
                    <span className='body_list'>
                      {singleuser.correspondingUsername}
                    </span>
                    <span className='body_list'>
                      {singleuser.details !== 'Credit'
                        ? singleuser.creditAmount !== '0'
                          ? ('+' + singleuser.creditAmount)
                          : ('-' + singleuser.debitAmount)
                        : singleuser.debitAmount !== '0'
                          ? ('-' + singleuser.debitAmount)
                          : ('+' + singleuser.creditAmount)
                      }
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
