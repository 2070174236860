import React from 'react'; 
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import { FooterMenu } from '../../../../Components/FooterMenu';
import { UserDetail } from '../../../../Components/Admin/Users';

export const AgentUserDetail = () => {
  const { t } = useTranslation();
  return (
    <div className='agent_layout'>
      <Header name={t('menu.memberProfile')} agentMenu={true} backToUrl={'/Agent/Users'} />
      <UserDetail />
      <FooterMenu />
    </div>
  );
};
