import React from 'react';
import { Outlet } from 'react-router-dom';

import './HomeTrial.scss';
import Loader from '../../../Components/Loader';
import Notification from '../../../Components/Notification';
import { useGameReducer } from '../../../Store';

const HomeTrial = () => {
  const gameReducer = useGameReducer();

  return (
    <>
      <Notification />
      <Outlet />
      {gameReducer.loading && <Loader />}
    </>
  );
};

export default HomeTrial;
