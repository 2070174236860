import React, { useState } from 'react'; 
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../../Components/Header';
import './AdminChangepassword.scss';
import { useUser } from '../../../Store';
import { FooterMenu } from '../../../Components/FooterMenu';
import { UserActions } from '../../../Store/Actions';

export const AgentChangePassword =  () => {
  const { t } = useTranslation();
  const user = useUser(useParams().userId);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...data };
    commonData[field] = event.target.value;
    return setData(commonData);
  };

  const submit = () => {
    if (data.password && data.confirmPassword === data.password) {
      UserActions.changePassword(user.user.name, data.password).then(() => {
        setData({password: '', confirmPassword: ''});
      });
    } else {
      setError(t('error.passNotMatch'));
    }
  };

  return (
    <div className='agent_layout pass_change'>
      <Header name={'Change Password'} />
      <div className='agent_body'>
        <div className='sub_header'>
          <span className='sub_header_text'>{t('user.name')}</span>
          <span className='sub_header_text'>{user.user.name}</span>
        </div>
        <ValidatorForm
          className='input_form_box'
          onSubmit={() => submit()}
          autoComplete='off'
        >
          <div className='input_form_box_inner'>
            <div className='input_form'>
              <span className='input_box_text'>Enter New Password</span>
              <TextValidator
                validators={['required']}
                errorMessages={['this field is required']}
                variant='outlined'
                size='small'
                type='password'
                name='password'
                onChange={handleChange}
                value={data.password}
                className='input_box'
              />
            </div>
            <div className='input_form'>
              <span className='input_box_text'>Confirm New Password</span>
              <TextValidator
                validators={['required']}
                errorMessages={['this field is required']}
                variant='outlined'
                size='small'
                type='password'
                name='confirmPassword'
                onChange={handleChange}
                value={data.confirmPassword}
                className='input_box'
              />
            </div>
          </div>
          { error && (
            <div className="error">{ error }</div>
          )}
          <div className='form_btn'>
            <Button type='submit' variant='contained' className='submit_btn'>
              Submit
            </Button>
          </div>
        </ValidatorForm>
      </div>
      <FooterMenu />
    </div>
  );
};
