import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import './BetHistory.scss';
import { BetHistory } from '../../../../Components/BetHistory';
import { FooterMenu } from '../../../../Components/FooterMenu';

export const AgentUserBetHistory = () => {
  const { userId } = useParams();
  const { t } = useTranslation();

  return (
    <div className='page_container bet_history'>
      <Header name={t('menu.betHistory')} />
      <div className="page_content full">
        <BetHistory userId={userId} />
      </div>
      <FooterMenu />
    </div>
  );
};
