/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { t } from 'i18next';

import './GameFooter.scss';
import { StepId } from '../../Constants/gameSteps';
import SicBoStatistic from '../SicBo/SicBoStatistic';
import GamePastResults from '../GamePastResults';
import { GameType } from '../../Utils';
import BaccaratStatistic from '../Baccarat/BaccaratStatistic';
import RouletteStatistic from '../Roulette/RouletteStatistic';

interface Props {
  minutes: number;
  confirmBet: any;
  confirmingBet: () => void;
  clearingBet: () => void;
  reload: () => void;
  handleNext: () => void;
  stepId: StepId;
  message: string;
  gameType: GameType;
}

export const GameFooter = (props: Props) => {
  const [pastResultActive, setPastResultActive] = useState(false);
  const [statisticsActive, setStatisticsActive] = useState(false);
  const [progressbar, setProgressbar] = useState(100);
  const [confirmTime, setConfirmTime] = useState(null);
  let confirmBetTimeout;
  const percentageValue = 15;

  useEffect(() => {
    const minutes = props.minutes - 1;
    if (minutes !== null && minutes <= percentageValue) {
      setProgressbar((minutes * 100) / percentageValue);
    } else {
      setProgressbar(100);
    }
  }, [props.minutes, percentageValue]);

  const updateNotificationRef = useRef();
  useEffect(() => {
    // @ts-ignore
    updateNotificationRef?.current?.animate(
      {
        marginTop: ['40px', 0, 0, 0, '-40px'],
        opacity: [0, 1, 1, 1, 0]
      },
      1010
    );
  }, [props.minutes]);

  useEffect(() => {
    if (props.confirmBet) {
      setConfirmTime(3);
    }
  }, [props.confirmBet]);

  useEffect(() => {
    setPastResultActive(false);
    setStatisticsActive(false);
  }, [props.stepId]);

  useEffect(() => {
    return () => { clearTimeout(confirmBetTimeout); };
  }, []);

  useEffect(() => {
    clearTimeout(confirmBetTimeout);
    if (confirmTime > 0) {
      confirmBetTimeout = setTimeout(() => {
        setConfirmTime(c => (c - 1));
      }, 1000);
    } else if (props.confirmBet) {
      props.confirmingBet();
    }
  }, [confirmTime]);

  const elCloseButton = (
    <button className='game_counter_btn' onClick={() => {
      setPastResultActive(false);
      setStatisticsActive(false);
    }}>
      <i className='fa-solid fa-xmark'></i>
    </button>
  );

  const elPastResultButton = (
    <button className='game_counter_btn game_chart_btn' onClick={() => {
      setStatisticsActive(false);
      setPastResultActive(true);
    }}>
      <i className='fa-solid fa-clock-rotate-left'></i>
    </button>
  );

  const elStatButton = (
    <button className='game_counter_btn game_chart_btn' onClick={() => {
      setStatisticsActive(true);
      setPastResultActive(false);
    }}>
      <i className='fa-solid fa-chart-simple'></i>
    </button>
  );

  const bettingFrame = () => {
    return (
      <React.Fragment>
        {pastResultActive && (
          <div className="dialog">
            <GamePastResults gameType={props.gameType} />
          </div>
        )}
        {statisticsActive && (
          <div className="dialog">
            {getGameStatistics(props.gameType)}
          </div>
        )}

        {pastResultActive && elCloseButton}

        {!pastResultActive && props.confirmBet !== undefined && props.confirmBet !== true && elPastResultButton}

        {!pastResultActive && props.confirmBet !== undefined && props.confirmBet === true && (
          <button
            className='game_counter_btn game_done_btn'
            onClick={() => props.confirmingBet()}
          >
            <i className='fa-solid fa-check'></i>
            <span className='number'>{confirmTime ? `${confirmTime}` : ''}</span>
          </button>
        )}

        <div className='game_counting'>
          <span className='game_counting_text'>
            {pastResultActive && (<span className=''>{t('gameState.pastResults')}</span> )}
            {statisticsActive && (<span className=''>{t('gameState.statistics')}</span> )}
            {!pastResultActive && !statisticsActive && (
              <span
                ref={updateNotificationRef}
                className={'game_counting_text_inner'}
                style={ { marginTop: '40px', opacity: 0 } }
              >
                {props.minutes}</span>
            )}
          </span>
          <ProgressBar
            transitionTimingFunction='linear'
            completed={progressbar}
            labelColor='#e80909'
            className='wrapper'
          />
        </div>

        {statisticsActive && elCloseButton}

        {!statisticsActive && !(props.confirmBet !== undefined && props.confirmBet === true) && elStatButton}

        {!statisticsActive && props.confirmBet !== undefined && props.confirmBet === true && (
          <button
            className='game_counter_btn game_close_btn'
            onClick={() => props.clearingBet()}
          >
            <i className='fa-solid fa-xmark'></i>
            <span className='number'>{confirmTime ? `${confirmTime}` : ''}</span>
          </button>
        )}
      </React.Fragment>
    );
  };

  const topPlayersBet = () => {
    return (
      <React.Fragment>
        <div className='game_counting'>
          <span className='game_counting_text game_message_text'>
            {props.minutes >= 4 && (<>{t('gameState.betClosed')}</>)}
            { !props.minutes || props.minutes < 4 && (<>{t('gameState.resultSoon')}</>) }
          </span>
          <ProgressBar
            transitionTimingFunction='linear'
            completed={progressbar}
            labelColor='#e80909'
            className={'wrapper progressbar_white'}
          />
        </div>
      </React.Fragment>
    );
  };

  const defaultMessageTemplate = () => {
    return (
      <React.Fragment>
        <div className='game_counting'>
          <span className='game_counting_text game_message_text'>{t(props.message)}</span>
        </div>
      </React.Fragment>
    );
  };

  const errorPage = () => {
    return (
      <React.Fragment>
        <div className='game_counting'></div>
      </React.Fragment>
    );
  };

  const networkError = () => {
    return (
      <React.Fragment>
        {props.minutes ? (
          <button className='game_counter_btn game_time_btn'>
            {props.minutes}
          </button>
        ) : (
          <button className='game_counter_btn game_menu_btn'>
            <i className='fa-solid fa-ellipsis'></i>
          </button>
        )}

        <div className='game_counting'>
          <span className='game_counting_text'>
            {props.message} <ArrowForwardIcon />
          </span>
        </div>

        <button className='game_counter_btn game_chart_btn' onClick={props.reload}>
          <i className='fa-solid fa-refresh'></i>
        </button>
      </React.Fragment>
    );
  };

  const getSectionByStep = (stepId: any) => {
    if (stepId === StepId.GAME) return bettingFrame();
    if (stepId === StepId.TOP_BETS) return topPlayersBet();
    if (stepId === StepId.RESULT) return defaultMessageTemplate();
    if (stepId === StepId.HIGHLIGHT_BETS) return defaultMessageTemplate();
    if (stepId === StepId.TOP_WINNERS) return defaultMessageTemplate();
    if (stepId === StepId.HIGHLIGHT_WINNER_BETS) return defaultMessageTemplate();
    if (stepId === StepId.LOADING) return defaultMessageTemplate();
    if (stepId === StepId.ERROR) return errorPage();
    if (stepId === 9) return defaultMessageTemplate();
    if (stepId === 10) return networkError();
  };

  const getGameStatistics = (gameType: GameType) => {
    switch (gameType) {
      case GameType.Baccarat: return <BaccaratStatistic />;
      case GameType.Roulette: return <RouletteStatistic />;
      case GameType.SicBo: return <SicBoStatistic />;
    }
  };

  return (
    <div className='footr'>
      <div className='game_counter'>
        {getSectionByStep(props.stepId)}
      </div>
    </div>
  );
};
