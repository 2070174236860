import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap'; 

import './RouletteStatistic.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouletteStatistics, useGameReducer } from '../../../Store';
import * as GameActions from '../../../Store/Actions/game.actions';
import { ROULETTE } from '../../../Constants';

const RouletteStatistic = () => {
  const gameReducer = useGameReducer();
  const [stats, setStats] = useState<RouletteStatistics>();

  useEffect(() => {
    if (gameReducer.session.id !== gameReducer.statistics.sessionId) {
      GameActions.getGameStatistics();
    }
  }, []);

  useEffect(() => {
    if (gameReducer.statistics.roulette) {
      setStats(gameReducer.statistics.roulette);
    }
  }, [gameReducer.statistics.roulette]);

  const getFieldColor = (number: number) => ROULETTE.find(r => r.value === number.toString())?.color;

  return (
    <div className='roulette_statistic'>
      <div className='stat_row hot_cool'>
        <div className='hot_cool_side hot_div'>
          <span className='title'>热门 Hot</span>
          <div className='fields'>
            { stats?.hotNumbers.map((n, i) => (
              <div className={'chip ' + getFieldColor(n)} key={i}>{n}</div>
            ))}
          </div>
        </div>

        <div className='hot_cool_side cool_div'>
          <span className='title'>冷门 Cool</span>
          <div className='fields'>
            { stats?.coldNumbers.map((n, i) => (
              <div className={'chip ' + getFieldColor(n)} key={i}>{n}</div>
            ))}
          </div>
        </div>
      </div>

      <div className='stat_row progress_stat'>
        <div className='label'>
          <img src='/img/red_diamond.svg' alt='Red'/>
        </div>
        <ProgressBar>
          <ProgressBar
            striped
            className='progressbar_red left'
            now={stats ? stats.redPercentage * 100 : 50}
            key={1}
            label={stats ? `${Math.round(stats.redPercentage * 100)}%` : ''}
          />
          <ProgressBar
            className='progressbar_green'
            now={stats ? (1-stats.redPercentage-stats.blackPercentage) * 100 : 0}
            key={2}
          />
          <ProgressBar
            striped
            className='progressbar_black right'
            now={stats ? stats.blackPercentage * 100 : 50}
            key={3}
            label={stats ? `${Math.round(stats.blackPercentage * 100)}%` : ''}
          />
        </ProgressBar>
        <div className='label'>
          <img src='/img/black_diamond.svg' alt='Black'/>
        </div>
      </div>

      <div className='stat_row progress_stat'>
        <div className='label'>1-9</div>
        <ProgressBar>
          <ProgressBar
            striped
            className='progressbar_low left'
            now={stats ? stats.smallPercentage * 100 : 50}
            key={1}
            label={stats ? `${Math.round(stats.smallPercentage * 100)}%` : ''}
          />
          <ProgressBar
            className='progressbar_green'
            now={stats ? (1-stats.smallPercentage-stats.bigPercentage) * 100 : 0}
            key={2}
          />
          <ProgressBar
            striped
            className='progressbar_high right'
            now={stats ? stats.bigPercentage * 100 : 50}
            key={3}
            label={stats ? `${Math.round(stats.bigPercentage * 100)}%` : ''}
          />
        </ProgressBar>
        <div className='label'>10-18</div>
      </div>

      <div className='stat_row progress_stat'>
        <div className='label'>
          <span>双</span>
          <span>even</span>
        </div>
        <ProgressBar>
          <ProgressBar
            striped
            className='progressbar_even left'
            now={stats ? stats.evenPercentage * 100 : 50}
            key={1}
            label={stats ? `${Math.round(stats.evenPercentage * 100)}%` : ''}
          />
          <ProgressBar
            className='progressbar_green'
            now={stats ? (1-stats.oddPercentage-stats.evenPercentage) * 100 : 0}
            key={2}
          />
          <ProgressBar
            striped
            className='progressbar_odd right'
            now={stats ? stats.oddPercentage * 100 : 50}
            key={3}
            label={stats ? `${Math.round(stats.oddPercentage * 100)}%` : ''}
          />
        </ProgressBar>
        <div className='label'>
          <span>单</span>
          <span>odd</span>
        </div>
      </div>
    </div>
  );
};

export default RouletteStatistic;
