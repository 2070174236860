import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../Components/Header';
import { FooterMenu } from '../../../Components/FooterMenu';
import { MessageDetails } from '../../../Components/Admin/MessageDetails';

const ManagerMessageDetails = () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.message')} />

      <div className='agent_body'>
        <MessageDetails />
      </div>

      <FooterMenu isManager={true}/>
    </div>
  );
};

export default ManagerMessageDetails;
