import React from 'react';

import Header from '../../../Components/Header';
import './Home.scss';
import { StartBalance, StartMessages, StartUserInfo } from '../../../Components/StartWidgets';
import { StartPages } from '../../../Components/StartWidgets/StartPages';

export const ManagerDashboard =  () => {
  return (
    <div className='agent_layout agent_home'>
      <Header logo={true} name={'Home'} agentMenu={true}/>
      <div className='agent_body'>
        <StartUserInfo />
        <StartBalance />
        <StartMessages />
        <StartPages isManager={true} />
      </div>
    </div>
  );
};
