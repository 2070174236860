import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './GameEngine.scss';
import { gameSteps, StepId } from '../../Constants/gameSteps';
import Header from '../Header';
import { GameHeader } from '../GameHeader';
import { GameOverlay } from '../GameOverlay';
import { GameType, useOnline, useVisibleChange } from '../../Utils';
import { GameFooter } from '../GameFooter';
import { ChipSlider } from '../ChipSlider';
import { GameReducerState, useGameReducer } from '../../Store';
import { APP_BASE_PATH, TRIAL_BASE_PATH } from '../../Constants';
import BaccaratBetting from '../Baccarat/BaccaratBetting';
import BettingFrame from '../Roulette/BettingFrame/BetFrames';
import SicboBetting from '../SicBo/SicboBetting';
import { WebsiteGames } from '../WebsiteGame';
import { GameActions, UserActions } from '../../Store/Actions';

interface Props {
  title: string;
  containerClass: string;
  gameType: GameType;
  trial?: boolean;
  noBet?: boolean;
  login?: boolean;
  website?: boolean;
}

const TRIAL_SESSION_LIMIT = 5;

export const GameEngine = (props: Props) => {
  const gameReducer = useGameReducer();
  const steps = [...gameSteps];
  const { t } = useTranslation();

  const [activeChips, setActiveChips] = useState(0);
  const [minutes, setMinutes] = useState<number>(null);
  const [message, setMessage] = useState<string>(null);
  const [sessionError, setSessionError] = useState(false);
  const isOnline = useOnline();
  const isVisible = useVisibleChange();
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [trialResult, setTrialResult] = useState<number>(null);
  const [trialSessions, setTrialSessions] = useState<number>(null);

  useEffect(() => {
    if (isVisible) {
      startGame();
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isOnline) {
      GameActions.nextStep(StepId.OFFLINE);
    } else {
      startGame();
    }
  }, [isOnline]);

  const startGame = (force?: boolean) => {
    if (isOnline && (!gameReducer.session || currentSessionId !== gameReducer.session?.id || force)) {
      GameActions.nextStep(StepId.GAME, gameReducer, props.gameType, props.trial, props.website);
    }
  };

  useEffect(() => {
    if (trialSessions === null) {
      setTrialResult(-1);
    } else if (trialSessions === 1) {
      startGame();
    }
  }, [trialSessions]);

  useEffect(() => {
    if (gameReducer.session !== null) {
      setCurrentSessionId(gameReducer.session.id);
      if (!props.noBet) {
        GameActions.getBetsBySession(gameReducer.userDetail?.id, gameReducer.session.id, props.gameType, props.trial);
      }
    }
  }, [gameReducer.session]);

  useEffect(() => {
    setMessage(gameReducer.message);
  }, [gameReducer.message]);

  useEffect(() => {
    if (gameReducer.showWinningArea) {
      let _message = '';
      switch (props.gameType) {
        case GameType.Baccarat:
          _message = t('result.betPayOutMessage', {bet: gameReducer.baccaratAppliedChips, winning: gameReducer.baccaratWinningChips});
          break;
        case GameType.Roulette:
          _message = t('result.betPayOutMessage', {bet: gameReducer.rouletteAppliedChips, winning: gameReducer.rouletteWinningChips});
          break;
        case GameType.SicBo:
          _message = t('result.betPayOutMessage', {bet: gameReducer.sicboAppliedChips, winning: gameReducer.sicboWinningChips});
          break;
        default:
          _message = t('result.betPayOutMessage', {bet: gameReducer.applyChips, winning: gameReducer.winningChips});
      }
      setMessage(_message);
    }
  }, [gameReducer.showWinningArea]);

  useEffect(() => {
    if (gameReducer.isError) {
      setSessionError(true);
      GameActions.nextStep(StepId.ERROR);
    }
  }, [gameReducer.isError]);

  useEffect(() => {
    if (gameReducer.stepTime) {
      setMinutes(gameReducer.stepTime);
    }
  }, [gameReducer.stepTime]);

  useEffect(() => {
    setActiveChips(getBets(props.gameType, gameReducer).reduce((acc, cur) => (acc + (cur.activeChip || 0)), 0));
  }, [gameReducer.baccarat, gameReducer.roulette, gameReducer.sicbo]);

  useEffect(() => {
    if (minutes !== null && !gameReducer.isError && isOnline && gameReducer.session !== null) {
      const activeStep = steps.find((data) => data.id === gameReducer.stepId);
      if (activeStep) {
        let timer: NodeJS.Timeout;
        if (minutes > 0) {
          timer = setTimeout(() => { setMinutes((minutes - 1)); }, 1000);
        } else if (minutes === 0) {
          if (props.trial && activeStep.id === StepId.HIGHLIGHT_WINNER_BETS && trialSessions !== null) {
            if (trialSessions >= TRIAL_SESSION_LIMIT) {
              setTrialResult(gameReducer.balance);
            } else {
              setTrialSessions(p => p + 1);
            }
          }
          GameActions.nextStep(activeStep.nextStep, gameReducer, props.gameType, props.trial, props.website);
        }
        return () => clearTimeout(timer);
      }
    }
  }, [minutes]);

  useEffect(() => {
    return () => {
      GameActions.resetGame();
    };
  }, []);

  const reload = () => {
    setSessionError(false);
    startGame();
  };

  const handleNext = () => {
    setMinutes(0);
  };

  const clearingBet = () => {
    GameActions.clearBetSelection(props.gameType);
  };

  const handleChip = (selectedChip) => {
    GameActions.applyChip(selectedChip, props.gameType);
  };

  const confirmingBet = () => {
    GameActions.confirmBets(
      props.trial,
      gameReducer.userDetail?.id,
      gameReducer.session.id,
      props.gameType,
      getBets(props.gameType, gameReducer),
    );
  };

  const startTrial = () => {
    setTrialResult(null);
    setTrialSessions(1);
    UserActions.initTrialBalance();
    startGame();
  };

  return (
    <div className={props.containerClass + ' page_container game_container'}>
      <Header
        name={props.title}
        leftMenu={props.website}
        backToUrl={!props.trial ? APP_BASE_PATH : TRIAL_BASE_PATH}
        background={props.gameType}
        gameType={props.gameType}
        login={props.login}/>

      <div className={'page_content ' + (props.website ? 'website_game' : '')}>
        { !props.noBet && (
          <GameHeader
            startTime={gameReducer.session?.startTime}
            balance={gameReducer.balance}
            bet={getAppliedChips(props.gameType, gameReducer)}
          />
        )}

        { props.gameType === GameType.Baccarat && <BaccaratBetting />}
        { props.gameType === GameType.Roulette && <BettingFrame />}
        { props.gameType === GameType.SicBo && <SicboBetting />}
        { props.gameType === null && (
          <WebsiteGames gameReducer={gameReducer} minutes={minutes} />
        ) }

        <GameOverlay
          stepId={gameReducer.stepId}
          minutes={minutes}
          sessionError={sessionError}
          isOnline={isOnline}
          reload={reload}
          website={props.website}
          trial={props.trial}
          trialResult={trialResult}
          trialStart={startTrial}
        />

        { !props.noBet && (
          <GameFooter
            message={message}
            gameType={props.gameType}
            clearingBet={clearingBet}
            confirmBet={gameReducer.confirmBet}
            confirmingBet={confirmingBet}
            minutes={minutes}
            stepId={gameReducer.stepId}
            handleNext={handleNext}
            reload={reload}
          />
        )}

        { !props.noBet && (
          <ChipSlider
            handleChip={handleChip}
            balance={gameReducer.balance}
            activeChips={activeChips}
            bets={getAppliedChips(props.gameType, gameReducer)}
            trial={props.trial}
          />
        )}
      </div>
    </div>
  );
};

const getBets = (gameType: GameType, reducer: GameReducerState) => {
  switch (gameType) {
    case GameType.Baccarat: return reducer.baccarat;
    case GameType.Roulette: return reducer.roulette;
    case GameType.SicBo: return reducer.sicbo;
    case null: return [...reducer.baccarat, ...reducer.roulette, ...reducer.sicbo];
    default: return null;
  }
};

const getAppliedChips = (gameType: GameType, reducer: GameReducerState) => {
  switch (gameType) {
    case GameType.Baccarat: return reducer.baccaratAppliedChips;
    case GameType.Roulette: return reducer.rouletteAppliedChips;
    case GameType.SicBo: return reducer.sicboAppliedChips;
    default: return null;
  }
};
