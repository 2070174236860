import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { store } from './Store';

import { Login } from './Views/Login';

// Website & Trial views
import { LandingPage } from './Views/Website/LandingPage';
import { HomeTrial } from './Views/Trial/HomeTrial';
import { TrialStart } from './Views/Trial/TrialStart';
import { TrialBaccarat } from './Views/Trial/TrialBaccarat';
import { TrialSicBo } from './Views/Trial/TrialSicbo';
import { TrialRoulette } from './Views/Trial/TrialRoulette';

// Agent views
import { HomeAgent } from './Views/Agent/HomeAgent';
import { AgentDashboard } from './Views/Agent/Home';
import { AgentReport } from './Views/Agent/Report';
import { AgentSuccessReport } from './Views/Agent/Report/SuccessReport';
import { AgentChangePassword } from './Views/Agent/ChangePassword';
import { AgentMessageList, AgentMessageNew, AgentMessageDetails } from './Views/Agent/Messages';
import { AgentUserDetail } from './Views/Agent/User/UserDetailHome';
import { AgentUserTransactions } from './Views/Agent/User/Transaction';
import { AgentTransactionDetails, AgentTransactionList } from './Views/Agent/Transaction';
import { AgentUserBetHistory } from './Views/Agent/User/BetHistory';
import { AgentDepositCredit } from './Views/Agent/User/DepositCredit';
import { AgentWithdrawCredit } from './Views/Agent/User/WithdrawCredit';
import { AgentUserReport } from './Views/Agent/User/Report';
import { AgentSuccessUserReport } from './Views/Agent/User/Report/AgentSuccessUserReport';
import { AgentUsersList } from './Views/Agent/User/Users';
import { AgentAddUser } from './Views/Agent/User/AddUser';

// Manager views
import { HomeManager } from './Views/Manager/HomeManager';
import { ManagerDashboard } from './Views/Manager/Home';
import { ManagerReport } from './Views/Manager/Report';
import { SuccessManagerReport } from './Views/Manager/Report/SuccessReport';
import { ManagerSetting } from './Views/Manager/Setting';
import { ManagerChangePassword } from './Views/Manager/ChangePassword';
import { ManagerMessageList, ManagerMessageNew, ManagerMessageDetails } from './Views/Manager/Messages';
import { ManagerTransactionDetails, ManagerTransactionList } from './Views/Manager/Transaction';
import { UserListOutlet } from './Components/Admin/UserListOutlet';
import { ManagerTransactions } from './Views/Manager/User/Transaction';
import { ManagerDepositCredit } from './Views/Manager/User/DepositCredit';
import { ManagerWithdrawCredit } from './Views/Manager/User/WithdrawCredit';
import { ManagerUserDetail } from './Views/Manager/User/UserDetailHome';
import { ManagerReportSearch } from './Views/Manager/User/Report';
import { ManagerSuccessReports } from './Views/Manager/User/Report/SuccessReport';
import { ManagerUsers } from './Views/Manager/User/Users';
import { ManagerAddUser } from './Views/Manager/User/AddUser';

// App views
import { PlayerBetHistory } from './Views/App/PlayerBetHistory';
import { TransactionRecord } from './Views/App/TransactionRecord';
import { SoundVibrate } from './Views/App/SoundVibrate';
import { Start } from './Views/App/Start';
import { HomeApp } from './Views/App/HomeApp';
import { AppSicBo } from './Views/App/AppSicbo';
import { AppRoulette } from './Views/App/AppRoulette';
import { AppBaccarat } from './Views/App/AppBaccarat';
import { ChangePassword } from './Components/ChangePassword';

import { LoggedIn, RequireAuthAgent, RequireAuthManager, RequireAuthPlayer, } from './Routes';
import { PageNotFound } from './Components/PageNotFound';
import { AdminUserDetailsOutlet } from './Components/Admin/AdminUserDetailsOutlet';

import EN_TRANSLATIONS from './i18n/en.json';
import CN_TRANSLATIONS from './i18n/cn.json';
import { APP_BASE_PATH, TRIAL_BASE_PATH } from './Constants';
import reportWebVitals from './reportWebVitals';
import './variables.scss';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: EN_TRANSLATIONS
      },
      cn: {
        translation: CN_TRANSLATIONS
      }
    },
    detection: {
      convertDetectedLanguage: (lng) => ['en', 'cn'].includes(lng.slice(0, 2)) ? lng.slice(0, 2) : 'cn',
    },
    fallbackLng: 'cn',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path='Login' element={
            <LoggedIn>
              <Login />
            </LoggedIn>
          }/>
          <Route path='' element={<LandingPage />}/>
          <Route path={TRIAL_BASE_PATH} element={<HomeTrial />}>
            <Route index path='' element={<TrialStart />}/>
            <Route path='Baccarat' element={<TrialBaccarat />} />
            <Route path='Roulette' element={<TrialRoulette />} />
            <Route path='Sicbo' element={<TrialSicBo />} />
          </Route>

          <Route path={APP_BASE_PATH} element={
            <RequireAuthPlayer>
              <HomeApp />
            </RequireAuthPlayer>
          }>
            <Route index path='' element={<Start />}/>
            <Route path='SoundVibrate' element={<SoundVibrate />}/>
            <Route path='ChangePassword' element={<ChangePassword />}/>
            <Route path='TransactionRecord' element={<TransactionRecord />}/>
            <Route path='BetHistory' element={<PlayerBetHistory />}/>

            <Route path='Roulette' element={<AppRoulette />} />
            <Route path='Baccarat' element={<AppBaccarat />} />
            <Route path='SicBo' element={<AppSicBo />} />
          </Route>

          <Route path='Agent' element={
            <RequireAuthAgent>
              <HomeAgent />
            </RequireAuthAgent>
          }>
            <Route index element={<AgentDashboard />} />
            <Route path='Report' element={<AgentReport />} />
            <Route path='SuccessReport' element={<AgentSuccessReport />}/>
            <Route path='Messages'>
              <Route path='' element={<AgentMessageList />} />
              <Route path='New' element={<AgentMessageNew />} />
              <Route path=':id' element={<AgentMessageDetails />} />
            </Route>
            <Route path='Users' element={<UserListOutlet />}>
              <Route index element={<AgentUsersList />} />
              <Route path='Add' element={<AgentAddUser />} />
              <Route path=':userId' element={<AdminUserDetailsOutlet />}>
                <Route index element={<AgentUserDetail />} />
                <Route path='DepositCredit' element={<AgentDepositCredit />} />
                <Route path='WithdrawCredit' element={<AgentWithdrawCredit />}/>
                <Route path='ChangePassword' element={<AgentChangePassword />}/>
                <Route path='Transactions'>
                  <Route index element={<AgentUserTransactions />} />
                  <Route path=':id' element={<AgentTransactionDetails />} />
                </Route>
                <Route path='Messages'>
                  <Route path='' element={<AgentMessageList />} />
                  <Route path='New' element={<AgentMessageNew />} />
                  <Route path=':id' element={<AgentMessageDetails />} />
                </Route>
                <Route path='Report' element={<AgentUserReport />} />
                <Route path='SuccessUserReport' element={<AgentSuccessUserReport />}/>
                <Route path='BetHistory' element={<AgentUserBetHistory />} />
              </Route>
            </Route>

            <Route path='Transactions'>
              <Route index element={<AgentTransactionList />} />
              <Route path=':id' element={<AgentTransactionDetails />} />
            </Route>
          </Route>

          <Route path='Manager' element={
            <RequireAuthManager>
              <HomeManager />
            </RequireAuthManager>
          }>
            <Route index element={<ManagerDashboard />} />

            <Route path='Report' element={<ManagerReport />} />
            <Route path='SuccessReport' element={<SuccessManagerReport />}/>
            <Route path='Setting' element={<ManagerSetting />} />
            <Route path='Setting/AdminChangepassword' element={<ManagerChangePassword />}/>
            <Route path='Messages'>
              <Route path='' element={<ManagerMessageList />} />
              <Route path='New' element={<ManagerMessageNew />} />
              <Route path=':id' element={<ManagerMessageDetails />}/>
            </Route>

            <Route path='Users' element={<UserListOutlet />}>
              <Route index element={<ManagerUsers />} />
              <Route path='Add' element={<ManagerAddUser />} />
              <Route path=':userId' element={<AdminUserDetailsOutlet />}>
                <Route index element={<ManagerUserDetail />} />
                <Route path='DepositCredit' element={<ManagerDepositCredit />}/>
                <Route path='WithdrawCredit' element={<ManagerWithdrawCredit />}/>
                <Route path='Transactions'>
                  <Route path='' element={<ManagerTransactions />}/>
                  <Route path=':id' element={<ManagerTransactionDetails />} />
                </Route>
                <Route path='Messages'>
                  <Route path='' element={<ManagerMessageList />} />
                  <Route path='New' element={<ManagerMessageNew />}/>
                  <Route path=':id' element={<ManagerMessageDetails />}/>
                </Route>
                <Route path='Report' element={<ManagerReportSearch />} />
                <Route path='SuccessUserReport' element={<ManagerSuccessReports />}/>
              </Route>
            </Route>

            <Route path='Transactions'>
              <Route index element={<ManagerTransactionList />} />
              <Route path=':id' element={<ManagerTransactionDetails />} />
            </Route>
          </Route>

          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
