import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../Components/Header';
import { FooterMenu } from '../../../Components/FooterMenu';
import { MessageNew } from '../../../Components/Admin/MessageNew';

const ManagerMessageNew = () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('messages.new')} />
      <div className='agent_body'>
        <MessageNew />
      </div>

      <FooterMenu isManager={true}/>
    </div>
  );
};

export default ManagerMessageNew;
