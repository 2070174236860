import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../Components/Header';
import { StartBalance, StartMessages, StartUserInfo } from '../../../Components/StartWidgets';
import './Home.scss';
import { StartPages } from '../../../Components/StartWidgets/StartPages';

export const AgentDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout agent_home'>
      <Header logo={true} name={t('Home')} agentMenu={true} />

      <div className='agent_body'>
        <StartUserInfo />
        <StartBalance />
        <StartMessages />
        <StartPages />
      </div>
    </div>
  );
};
