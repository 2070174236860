import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom'; 

import { useUser } from '../../Store';

export const AdminUserDetailsOutlet = () => {
  const navigate = useNavigate();
  const user = useUser(useParams().userId);

  useEffect(() => {
    if (!user) {
      navigate('/Manager/Users');
    }
  }, [user]);

  return user ? <Outlet /> : <></>;
};
