import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Header from '../../../../Components/Header';
import { UserTransactions } from '../../../../Components/Admin/Users';
import { FooterMenu } from '../../../../Components/FooterMenu';
import { UserActions } from '../../../../Store/Actions';

export const ManagerTransactions = () => {
  const { userId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    UserActions.getUserTransactions(undefined, undefined, userId);
  }, []);

  return (
    <div className='agent_layout'>
      <Header name={t('userAction.transactions')} />
      <UserTransactions />
      <FooterMenu isManager={true}/>
    </div>
  );
};
