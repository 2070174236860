import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import { UserList } from '../../../../Components/Admin/Users';
import { FooterMenu } from '../../../../Components/FooterMenu';

export const ManagerUsers = () => {
  const {t} = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.members')} agentMenu={true} backToHome={true} />
      <UserList />
      <FooterMenu isManager={true}/>
    </div>
  );
};
