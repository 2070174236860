import React from 'react';
import '@fontsource/inter';
import '@fortawesome/fontawesome-free/css/all.min.css';

import PageNotSpotted from '../../../Components/PageNotSpotted';
import { GameType } from '../../../Utils';
import './AppBaccarat.scss';
import { GameEngine } from '../../../Components/GameEngine';

export const AppBaccarat = () => {
  return (
    <>
      <GameEngine
        title='百家乐 Baccarat'
        containerClass='homebaccarat'
        gameType={GameType.Baccarat}
      />
      <PageNotSpotted />
    </>
  );
};
