import React from 'react';
import '@fontsource/inter';
import '@fortawesome/fontawesome-free/css/all.min.css';

import PageNotSpotted from '../../../Components/PageNotSpotted';
import { GameType } from '../../../Utils';
import './TrialRoulette.scss';
import { GameEngine } from '../../../Components/GameEngine';

const TrialRoulette = () => {
  return (
    <>
      <GameEngine
        title='轮盘 Roulette'
        containerClass='homeroulette'
        gameType={GameType.Roulette}
        trial={true}
      />
      <PageNotSpotted />
    </>
  );
};

export default TrialRoulette;
