import { useEffect, useState } from 'react';
import React from 'redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { DATE_FORMAT, TIME_FORMAT } from '../../environment.config';
import { useGameReducer } from '../../Store';
import './StartMessages.scss';
import { UserActions } from '../../Store/Actions';

export const StartMessages = () => {
  const gameReducer = useGameReducer();
  const {t} = useTranslation();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    UserActions.getMessages();
  }, []);

  useEffect(() => {
    setMessages(gameReducer.messages);
  }, [gameReducer.messages]);

  return (
    <div className='messages_widget'>
      <div className='message_icon'></div>
      <div className='message_details'>
        {messages && messages[0] && (
          <>
            <div className='message_info'>
              <div className='message_sender'>{messages[0].senderId}</div>
              <div
                className='message_time'>{moment(messages[0].createdAt).format(DATE_FORMAT) + ' ' + moment(messages[0].createdAt).format(TIME_FORMAT)}</div>
            </div>
            <div className='message'>{messages[0].subject}</div>
          </>
        )}
        {(!messages || !messages[0]) && (
          <div className='error'>{t('messages.noNew')}</div>
        )}
      </div>
    </div>
  );
};
