import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import '@fontsource/inter';

import '../../../Styles/agent-style.scss';
import './HomeAgent.scss';
import Loader from '../../../Components/Loader';
import Notification from '../../../Components/Notification';
import { useGameReducer } from '../../../Store';
import { UserActions } from '../../../Store/Actions';

export const HomeAgent = () => {
  const navigate = useNavigate();
  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.token) {
      UserActions.getUserDetails();
    } else {
      navigate('/Login');
    }
  }, [gameReducer.token]);

  useEffect(() => {
    if (gameReducer.userDetail) {
      UserActions.getUserBalanceAndMessages();
    }
  }, [gameReducer.userDetail]);

  return (
    <>
      <Notification />
      <div className='page_container agent_container'>
        <Outlet />
        {gameReducer.loading && <Loader />}
      </div>
    </>
  );
};
