/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import {
  IN_PROGRESS,
  IS_AUTHENTICATED,
  ERROR,
  LOGOUT,
  GET_USER,
  GET_USER_BALANCE_MESSAGES,
  GET_GAME_TIME,
  ROULETTE_CLEAR_BET_SELECTION,
  SICBO_CLEAR_BET_SELECTION,
  ROULETTE_APPLY_CHIP,
  SICBO_APPLY_CHIP,
  ROULETTE_CONFIRMING_BET,
  SICBO_CONFIRMING_BET,
  GET_USERS,
  COMPLETE,
  NEW_USER,
  SUCCESS_MESSAGE,
  GET_USER_TRANSACTION,
  BACCARAT_APPLY_CHIP,
  BACCARAT_CONFIRMING_BET,
  BACCARAT_CLEAR_BET_SELECTION,
  GET_TOP_BETS,
  ROULETTE_RESULT,
  ROULETTE_BETS_BY_SESSION,
  ROULETTE_SHOW_WINNING_AREA,
  BACCARAT_BETS_BY_SESSION,
  BACCARAT_RESULT,
  BACCARAT_SHOW_WINNING_AREA,
  ERROR_PAGE,
  SICBO_SHOW_WINNING_AREA,
  SICBO_BETS_BY_SESSION,
  SICBO_RESULT,
  ROULETTE_BET_SELECTION,
  SICBO_BET_SELECTION,
  BACCARAT_BET_SELECTION,
  ROULETTE_SHOW_RESULT_AREA,
  BACCARAT_SHOW_RESULT_AREA,
  SICBO_SHOW_RESULT_AREA,
  GET_USER_BETS,
  GET_TOP_WINNERS,
  RESET_GAME,
  GET_MESSAGES,
  GET_PAST_RESULTS,
  RESET_USER_TRANSACTION,
  INIT_TRIAL_BALANCE,
  SET_STEP,
  CHANGE_TRIAL_BALANCE,
  GET_GAME_STATISTICS,
  ALL_SHOW_WINNING_AREA,
  ALL_RESULT,
  ALL_SHOW_RESULT_AREA,
  SET_USER_DETAILS,
} from '../Constants';
import initialState from './initialState';
import { BACCARAT, ROULETTE, SICBO, PAGINATION_LIMIT } from '../Constants';
import { cloneDeep } from 'lodash';
import { getDefaultChips, getTopAvailableChip, intToString } from '../Utils';
import { GameReducerState } from './app.state';
import { DEFAULT_TOP_CHIP, TRIAL_BET_LIMIT, TRIAL_DEFAULT_BALANCE } from '../environment.config';
import { StepId } from '../Constants/gameSteps';

export default function gameReducer(state = initialState.gameReducer, action) {
  switch (action.type) {
    case IN_PROGRESS:
      return {
        ...state,
        error_msg: '',
        success_msg: null,
        transactionMessage: null,
        loading: true,
        isError: false,
      };

    case COMPLETE:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
      };

    case ERROR:
      return {
        ...state,
        error_msg: action.data.error_msg + 'E:' + Math.random(),
        success_msg: null,
        transactionMessage: null,
        loading: false,
      };

    case IS_AUTHENTICATED:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        token: action.data.accessToken,
      };

    case LOGOUT:
      return {
        ...state,
        loading: false,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        token: null,
        roulette: cloneDeep(ROULETTE),
        baccarat: cloneDeep(BACCARAT),
        sicbo: cloneDeep(SICBO),
        isChipsActive: false,
        userDetail: null,
        users: [],
        newUser: null,
        balance: null,
        selectedChip: null,
        accountId: null,
        messages: null,
      };

    case GET_USER:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        userDetail: action.data.userDetail,
      };

    case GET_MESSAGES:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        loading: false,
        messages: action.data.messages,
      };

    case GET_USER_BALANCE_MESSAGES:
      const balance = Math.floor(action.data.userBalance.amount);
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        balance,
        winningChips: 0,
        selectedChip: getTopAvailableChip(
          getDefaultChips().filter(c => state.selectedChip ? c.value <= parseInt(state.selectedChip) : c.value <= DEFAULT_TOP_CHIP),
          balance),
        accountId: action.data.userBalance.accountId,
        messages: null,
      };

    case INIT_TRIAL_BALANCE:
      const trialBalance = TRIAL_DEFAULT_BALANCE;
      return {
        ...state,
        balance: trialBalance,
        selectedChip: getTopAvailableChip(
          getDefaultChips().filter(c => state.selectedChip ? c.value <= parseInt(state.selectedChip) : c.value <= DEFAULT_TOP_CHIP),
          trialBalance),
      };

    case CHANGE_TRIAL_BALANCE:
      const newTrialBalance = state.balance + action.amount;
      const sessionLimit = TRIAL_BET_LIMIT - (state.baccaratAppliedChips + state.rouletteAppliedChips + state.sicboAppliedChips);
      return {
        ...state,
        balance: newTrialBalance,
        selectedChip: getTopAvailableChip(getDefaultChips().filter(c => c.value <= DEFAULT_TOP_CHIP), sessionLimit),
      };


    case RESET_GAME:
      return {
        ...state,
        trial: !!action.trial,
        error_msg: null,
        rouletteResult: null,
        baccaratResult: null,
        showWinningArea: false,
        baccaratGameResult: null,
        sicboGameResult: null,
        rouletteGameResult: null,
        playerTotal: [],
        bankerTotal: [],
        sicboResult: null,
        roulette: cloneDeep(ROULETTE),
        baccarat: cloneDeep(BACCARAT),
        sicbo: cloneDeep(SICBO),
        stepTime: null,
        topBets: [],
        topWinners: [],
        isError: false,
        baccaratCardLength: 0,
      };

    case GET_GAME_TIME:
      return {
        ...state,
        error_msg: null,
        loading: false,
        session: cloneDeep(action.data.session),
        lastSession: cloneDeep(action.data.lastSession),
        // rouletteResult: null,
        // baccaratResult: null,
        // showWinningArea: false,
        // baccaratGameResult: null,
        // sicboGameResult: null,
        // rouletteGameResult: null,
        // playerTotal: [],
        // bankerTotal: [],
        // sicboResult: null,
        // roulette: cloneDeep(ROULETTE),
        // baccarat: cloneDeep(BACCARAT),
        // sicbo: cloneDeep(SICBO),
        topBets: [],
        topWinners: [],
        isError: false,
        // baccaratCardLength: 0,
      };

    case GET_PAST_RESULTS:
      return {
        ...state,
        loading: false,
        pastResults: action.data.pastResults
      };

    case GET_GAME_STATISTICS:
      return {
        ...state,
        loading: false,
        statistics: {...action.data, sessionId: state.session.id}
      };
    case RESET_USER_TRANSACTION:
      return {
        ...state,
        creditTransactions: [],
        creditTransactionsHasMore: false,
      };
    case GET_USER_TRANSACTION:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        creditTransactions: action.data.resetList ? action.data.creditTransactions : [...state.creditTransactions, ...action.data.creditTransactions],
        creditTransactionsHasMore: action.data.creditTransactions.length === PAGINATION_LIMIT
      };

    case GET_USER_BETS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        betHistory: action.data.resetList ? action.data.betHistory : [...state.betHistory, ...action.data.betHistory],
        betHistoryHasMore: action.data.betHistory.length === PAGINATION_LIMIT
      };

    case GET_TOP_BETS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        topBets: action.data.topBets || [],
      };

    case GET_TOP_WINNERS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        topWinners: action.data.topWinners || [],
      };

      // Roulette
    case ROULETTE_BET_SELECTION:
      state.roulette.map((data) => {
        if (data.value === action.data.value) {
          data.activeChip += parseInt(state.selectedChip);
        }
      });
      return {
        ...state,
        roulette: [...state.roulette],
        confirmBet: true,
        rouletteMessage: action.data.value,
      };

    case SICBO_BET_SELECTION:
      state.sicbo.map((data) => {
        if (data.value === action.data.value) {
          data.activeChip += parseInt(state.selectedChip);
        }
      });
      return {
        ...state,
        sicbo: [...state.sicbo],
        confirmBet: true,
        rouletteMessage: action.data.value,
      };

    case BACCARAT_BET_SELECTION:
      state.baccarat.map((data) => {
        if (data.backEndValue === action.data.backEndValue) {
          data.activeChip += parseInt(state.selectedChip);
        }
      });
      return {
        ...state,
        baccarat: [...state.baccarat],
        confirmBet: true,
        rouletteMessage: action.data.value,
      };

    case SICBO_APPLY_CHIP:
      return {
        ...state,
        sicbo: [...state.sicbo],
        selectedChip: action.data,
        isSicboChipsActive: true,
        rouletteMessage: 'Please Confirm Bet',
      };

    case BACCARAT_APPLY_CHIP:
      return {
        ...state,
        baccarat: [...state.baccarat],
        selectedChip: action.data,
        isBaccaratChipsActive: true,
        rouletteMessage: 'Please Confirm Bet',
      };

    case ROULETTE_APPLY_CHIP:
      return {
        ...state,
        roulette: [...state.roulette],
        selectedChip: action.data,
        isChipsActive: true,
        rouletteMessage: 'Please Confirm Bet',
      };

    case ROULETTE_CLEAR_BET_SELECTION:
      state.roulette.map((data) => {
        data.isActive = false;
        data.activeChip = null;
      });
      return {
        ...state,
        roulette: [...state.roulette],
        isChipsActive: false,
        confirmBet: false,
      };

    case ROULETTE_BETS_BY_SESSION:
      let appliedChip = 0;
      state.roulette.map((roulette) => {
        roulette.isActive = false;
        roulette.chip = null;
        roulette.isWinner = null;
        action.data.map((response) => {
          if (response.fieldName === roulette.backEndValue) {
            appliedChip += parseInt(response.amount);
            roulette.chip += parseInt(response.amount);
          }
        });
      });

      return {
        ...state,
        loading: false,
        isChipsActive: false,
        confirmBet: false,
        applyChips: appliedChip,
        roulette: [...state.roulette],
        rouletteAppliedChips: appliedChip,
      };

    case BACCARAT_BETS_BY_SESSION:
      let baccaratAppliedChip = 0;
      state.baccarat.map((baccarat) => {
        baccarat.isActive = false;
        baccarat.chip = null;
        baccarat.isWinner = null;
        action.data.map((response) => {
          if (response.fieldName === baccarat.backEndValue) {
            baccaratAppliedChip += parseInt(response.amount);
            baccarat.chip += parseInt(response.amount);
          }
        });
      });
      return {
        ...state,
        loading: false,
        isChipsActive: false,
        confirmBet: false,
        applyChips: baccaratAppliedChip,
        baccarat: [...state.baccarat],
        baccaratAppliedChips: baccaratAppliedChip,
      };

    case SICBO_BETS_BY_SESSION:
      let sicboAppliedChip = 0;
      state.sicbo.map((sicbo) => {
        sicbo.isActive = false;
        sicbo.isWinner = null;
        sicbo.chip = null;
        action.data.map((response) => {
          if (response.fieldName == sicbo.backEndValue) {
            sicboAppliedChip += parseInt(response.amount);
            sicbo.chip += parseInt(response.amount);
          }
        });
      });
      return {
        ...state,
        loading: false,
        isChipsActive: false,
        confirmBet: false,
        applyChips: sicboAppliedChip,
        sicbo: [...state.sicbo],
        sicboAppliedChips: sicboAppliedChip,
      };

    case ROULETTE_CONFIRMING_BET:
      state.roulette.map((data) => {
        data.isActive = false;
        if (data.activeChip !== null) {
          state.rouletteAppliedChips += parseInt(data.activeChip);
          data.chip += parseInt(data.activeChip);
          data.activeChip = null;
          data.isConfirm = true;
        }
      });
      //Need to Fillter result and Display
      return {
        ...state,
        loading: false,
        roulette: [...state.roulette],
        confirmBet: false,
        isChipsActive: false,
        rouletteMessage: 'Bet Accepted',
      };

    case SICBO_CONFIRMING_BET:
      state.sicbo.map((data) => {
        data.isActive = false;
        if (data.activeChip !== null) {
          state.sicboAppliedChips += parseInt(data.activeChip);
          data.chip += parseInt(data.activeChip);
          data.activeChip = null;
          data.isConfirm = true;
        }
      });
      //Need to Fillter result and Display
      return {
        ...state,
        loading: false,
        sicbo: [...state.sicbo],
        confirmBet: false,
        isSicboChipsActive: false,
        rouletteMessage: 'Bet Accepted',
        sicboAppliedChip: state.sicboAppliedChips,
      };

    case BACCARAT_CONFIRMING_BET:
      let totalChips = 0;
      state.baccarat.map((data) => {
        data.isActive = false;
        if (data.activeChip !== null) {
          data.chip += parseInt(data.activeChip);
          state.baccaratAppliedChips += parseInt(data.activeChip);
          totalChips += parseInt(data.activeChip);
          data.activeChip = null;
          data.isConfirm = true;
        }
      });
      //Need to Filter result and Display
      return {
        ...state,
        loading: false,
        balance: state.trial ? (state.balance - totalChips) : state.balance,
        baccarat: [...state.baccarat],
        confirmBet: false,
        isBaccaratChipsActive: false,
        rouletteMessage: 'Bet Accepted',
        baccaratAppliedChips: state.baccaratAppliedChips,
      };

    case SICBO_CLEAR_BET_SELECTION:
      state.sicbo.map((data) => {
        data.isActive = false;
        data.activeChip = null;
      });
      return {
        ...state,
        sicbo: [...state.sicbo],
        isSicboChipsActive: false,
        confirmBet: false,
      };

    case SET_STEP:
      return {
        ...state,
        stepId: action.stepId,
        disableBet: action.stepId !== StepId.GAME,
        stepTime: action.stepTime,
        loading: false,
        message: action.message
      };

    case BACCARAT_CLEAR_BET_SELECTION:
      state.baccarat.map((data) => {
        data.isActive = false;
        data.activeChip = null;
      });
      return {
        ...state,
        baccarat: [...state.baccarat],
        isBaccaratChipsActive: false,
        confirmBet: false,
      };

    case ALL_SHOW_WINNING_AREA:
      return rouletteShowWinningArea(baccaratShowWinningArea(sicboShowWinningArea(state)));

    case ROULETTE_SHOW_WINNING_AREA:
      return rouletteShowWinningArea(state);

    case BACCARAT_SHOW_WINNING_AREA:
      return baccaratShowWinningArea(state);

    case SICBO_SHOW_WINNING_AREA:
      return sicboShowWinningArea(state);

    case ALL_RESULT:
      return rouletteResult(baccaratResult(sicboResult(state, action), action), action);

    case ROULETTE_RESULT:
      return rouletteResult(state, action);

    case BACCARAT_RESULT:
      return baccaratResult(state, action);

    case SICBO_RESULT:
      return sicboResult(state, action);

    case ALL_SHOW_RESULT_AREA:
      return rouletteShowResultArea(baccaratShowResultArea(sicboShowResultArea(state)));

    case ROULETTE_SHOW_RESULT_AREA:
      return rouletteShowResultArea(state);

    case BACCARAT_SHOW_RESULT_AREA:
      return baccaratShowResultArea(state);

    case SICBO_SHOW_RESULT_AREA:
      return sicboShowResultArea(state);

    case ERROR_PAGE:
      return {
        ...state,
        loading: false,
        isError: true,
        error_msg: state.error_msg || 'Could Not Fetch Data',
        success_msg: null,
        roulette: [...state.roulette],
        baccarat: [...state.baccarat],
        sicbo: [...state.sicbo],
        rouletteAppliedChips: 0,
        sicboAppliedChips: 0,
        baccaratAppliedChips: 0,
      };

    case GET_USERS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        users: action.data.users,
      };

    case SET_USER_DETAILS:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        users: [...state.users.map(u => {
          if (action.data.user.id === u.user.id) {
            u.user = action.data.user;
          }
          return u;
        })],
      };

    case NEW_USER:
      return {
        ...state,
        error_msg: null,
        success_msg: null,
        transactionMessage: null,
        loading: false,
        newUser: action.data.user,
        users: [...state.users, {balance: '0', user: action.data.user}],
      };

    case SUCCESS_MESSAGE:
      return {
        ...state,
        error_msg: null,
        success_msg: action.data.message,
        loading: false,
      };

    default:
      return state;
  }
}

const rouletteResult = (state: GameReducerState, action): GameReducerState => {
  const rouletteResult = action.data?.results?.roulette?.field;

  return {
    ...state,
    loading: false,
    error_msg: null,
    success_msg: null,
    rouletteResult: rouletteResult,
    rouletteGameResult: action.data?.results?.roulette,
  };
};

const baccaratResult = (state: GameReducerState, action): GameReducerState => {
  const baccaratResult = action.data?.results?.baccarat?.winner;
  const playerCards = action.data?.results?.baccarat?.playerCards;
  const bankerCards = action.data?.results?.baccarat?.bankerCards;
  let cardLength = 0;
  const bankerCardTotal = [];
  const playerCardTotal = [];
  let playerTotal = 0;
  let bankerTotal = 0;

  playerCards.map((card) => {
    cardLength++;
    if (card.figure === 'A') {
      playerTotal += 1;
    } else if (
      card.figure === 'J' ||
      card.figure === 'Q' ||
      card.figure === 'K'
    ) {
      playerTotal += 0;
    } else {
      playerTotal += Number(card.figure);
    }
    playerCardTotal.push(playerTotal % 10);
  });

  bankerCards.map((card) => {
    cardLength++;
    if (card.figure === 'A') {
      bankerTotal += 1;
    } else if (
      card.figure === 'J' ||
      card.figure === 'Q' ||
      card.figure === 'K'
    ) {
      bankerTotal += 0;
    } else {
      bankerTotal += Number(card.figure);
    }
    bankerCardTotal.push(bankerTotal % 10);
  });

  if (playerTotal >= 10) {
    playerTotal = playerTotal %= 10;
  }

  if (bankerTotal >= 10) {
    bankerTotal = bankerTotal %= 10;
  }

  return {
    ...state,
    loading: false,
    error_msg: null,
    success_msg: null,
    playerTotal: playerCardTotal,
    bankerTotal: bankerCardTotal,
    baccaratResult: baccaratResult,
    baccaratGameResult: action.data?.results?.baccarat,
    baccaratCardLength: cardLength,
  };
};

const sicboResult = (state: GameReducerState, action): GameReducerState => {
  const sicboResult = action.data?.results?.sicbo?.dice;

  return {
    ...state,
    loading: false,
    error_msg: null,
    success_msg: null,
    sicboResult: sicboResult,
    sicboGameResult: action.data?.results?.sicbo,
  };
};

const rouletteShowWinningArea = (state: GameReducerState): GameReducerState => {
  let result = state.rouletteGameResult?.field;
  const multipliers = state.rouletteGameResult?.multipliers;
  let winningAmount = 0;
  if (result === 0) {
    result = intToString(result);
  }
  state.roulette.map((roulette) => {
    if (result) {
      if (roulette.chip !== null) {
        if (multipliers[roulette.backEndValue] !== 0) {
          roulette.isConfirm = true;
          roulette.isWinner = true;
          roulette.chip =
            roulette.chip * multipliers[roulette.backEndValue];
          winningAmount += roulette.chip;
        } else {
          roulette.isWinner = false;
          roulette.chip = null;
        }
      }
    }
  });

  return {
    ...state,
    isChipsActive: false,
    confirmBet: false,
    error_msg: null,
    success_msg: null,
    rouletteWinningChips: winningAmount,
    winningChips: state.winningChips + winningAmount,
    balance: Math.round(state.balance + winningAmount),
    showWinningArea: true,
    roulette: [...state.roulette],
  };
};

const baccaratShowWinningArea = (state: GameReducerState): GameReducerState => {
  const baccaratWinner = state.baccaratGameResult?.winner;
  const baccarat_Multipliers = state.baccaratGameResult?.multipliers;
  let winningAmount = 0;
  state.baccarat.map((baccarat) => {
    if (baccaratWinner) {
      if (baccarat.chip !== null) {
        if (baccarat_Multipliers[baccarat.backEndValue] !== 0) {
          baccarat.isConfirm = true;
          baccarat.isWinner = true;
          baccarat.chip =
            baccarat.chip * baccarat_Multipliers[baccarat.backEndValue];
          winningAmount += baccarat.chip;
        } else {
          baccarat.isWinner = false;
          baccarat.chip = null;
        }
      }
    }
  });
  return {
    ...state,
    isChipsActive: false,
    confirmBet: false,
    error_msg: null,
    success_msg: null,
    baccaratWinningChips: winningAmount,
    winningChips: state.winningChips + winningAmount,
    balance: Math.round(state.balance + winningAmount),
    showWinningArea: true,
    baccarat: [...state.baccarat],
  };
};

const sicboShowWinningArea = (state: GameReducerState): GameReducerState => {
  let winningAmount = 0;
  const sicbo_Multipliers = state.sicboGameResult?.multipliers;
  state.sicbo.map((sicbo) => {
    if (state.sicboResult) {
      if (sicbo.chip !== null) {
        if (sicbo_Multipliers[sicbo.backEndValue] !== 0) {
          sicbo.isConfirm = true;
          sicbo.isWinner = true;
          sicbo.chip = sicbo.chip * sicbo_Multipliers[sicbo.backEndValue];
          winningAmount += sicbo.chip;
        } else {
          sicbo.isWinner = false;
          sicbo.chip = null;
        }
      }
    }
  });
  return {
    ...state,
    isChipsActive: false,
    confirmBet: false,
    error_msg: null,
    success_msg: null,
    sicboWinningChips: winningAmount,
    winningChips: state.winningChips + winningAmount,
    balance: Math.round(state.balance + winningAmount),
    showWinningArea: true,
    sicbo: [...state.sicbo],
  };
};

const rouletteShowResultArea = (state: GameReducerState): GameReducerState => {
  const multipliers = state.rouletteGameResult?.multipliers;
  if (state.rouletteResult === 0) {
    state.rouletteResult = intToString(state.rouletteResult);
  }
  state.roulette.forEach(field => { field.isActive = false; });
  state.roulette.map((roulette) => {
    roulette.isConfirm = false;
    if (state.rouletteResult) {
      if (multipliers[roulette.backEndValue] !== 0) {
        roulette.isActive = true;
      }
    }
  });

  return {
    ...state,
    loading: false,
    error_msg: null,
    success_msg: null,
    roulette: [...state.roulette],
  };
};

const baccaratShowResultArea = (state: GameReducerState): GameReducerState => {
  // const baccaratResult = action.data?.results?.baccarat?.winner;
  // const baccaratResultValue = action.data?.results?.baccarat?.winner;
  const multipliers = state.baccaratGameResult?.multipliers || {};

  state.baccarat.forEach(field => { field.isActive = false; });
  state.baccarat.map((baccarat) => {
    baccarat.isConfirm = false;
    if (state.baccaratResult) {
      if (multipliers[baccarat.backEndValue] > 1) {
        baccarat.isActive = true;
      }
    }
  });

  return {
    ...state,
    loading: false,
    error_msg: null,
    success_msg: null,
    baccarat: [...state.baccarat],
  };
};

const sicboShowResultArea = (state: GameReducerState): GameReducerState => {
  const multipliers = state.sicboGameResult.multipliers;

  state.sicbo.forEach(field => { field.isActive = false; });
  state.sicbo.map((sicbo) => {
    sicbo.isConfirm = false;
    if (state.sicboResult) {
      if (multipliers[sicbo.backEndValue] !== 0) {
        sicbo.isActive = true;
      }
    }
  });

  return {
    ...state,
    loading: false,
    error_msg: null,
    success_msg: null,
    baccarat: [...state.baccarat],
  };
};
