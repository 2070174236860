import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserWithBalance } from '../../../Store';
import { roundBalance } from '../../../Utils';
import { defaultLabels } from '../../../Utils/label.utils';
import './UserList.scss';
import { UserActions } from '../../../Store/Actions';

interface Props {
  isAgent?: boolean;
}

export const UserList = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [users, setUsers] = useState<UserWithBalance[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<string>(null);
  const [search, setSearch] = useState({
    search: '',
  });
  const labels = [
    { id: 'name', label: t('user.name'), subLabel: `(${t('user.displayName')})` },
    // { id: 'label', label: t('user.label') },
    { id: 'balance', label: t('user.balance') },
    { id: 'details', label: t('user.details') }
  ];

  const fetchUsers = () => {
    UserActions.getUsers(search.search, true, selectedLabel).then((_users: UserWithBalance[]) => {
      setUsers(_users);
    }).catch(() => {
      setUsers([]);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [selectedLabel]);

  const selectLabel = (label: string) => {
    if (selectedLabel === label) {
      setSelectedLabel(null);
    } else {
      setSelectedLabel(label);
    }
  };

  const handleChange = (event) => {
    const field = event.target.name;
    const commonData = { ...search };
    commonData[field] = event.target.value;
    return setSearch(commonData);
  };

  const handleKeyDown = (e) => {
    if(e.keyCode == 13) {
      fetchUsers();
    }
  };

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <div className='agent_body full_width'>
      <div className='search_box'>
        <TextField
          variant='outlined'
          size='small'
          type='text'
          name='search'
          className='search_box_inner'
          placeholder={t('label.search')}
          value={search.search}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' onClick={() => fetchUsers()}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      { props.isAgent && (
        <div className="filter_row">
          {defaultLabels.map((label) => (
            <button key={label} className={(label === selectedLabel ? 'selected' : '')} onClick={() => selectLabel(label)}>
              {t('labels.'+label)} { label === selectedLabel && <i className='fa-solid fa-close'></i> }
            </button>
          ))}
        </div>
      )}
      <div className='full_table button_rows' id='user_table'>
        <div className='table_header'>
          {
            labels.map((label, i) => (
              <div className={'table_header_text ' + (label.subLabel ? 'row2' : '')} key={'header.' + i}>
                <div>{label.label}</div>
                <div>{label.subLabel}</div>
              </div>
            ))
          }
        </div>
        <div className='table_body_main'>
          { search.search && users.length === 0 && (
            <div className='info_message'>{t('users.notFound', {search: search.search})}</div>
          )}
          {[...users].sort((a, b) => b.user?.created.localeCompare(a.user?.created)).map((singleUser, index) => (
            <div className='table_body_inner' key={'user.'+index}>
              <div className='table_body'>
                <div
                  className='table_body_list'
                  onClick={() => redirect(`${singleUser.user.id}`)}
                >
                  <span className='body_list row2'>
                    <div>{singleUser.user.name}</div>
                    <div>({singleUser.user.displayName})</div>
                  </span>
                  {/*<span className='body_list'>*/}
                  {/*  {singleUser.user.labels.sort((a, b) => a.localeCompare(b)).map(l => t(`labels.${l}`)).join(', ')}*/}
                  {/*</span>*/}
                  <span className='body_list'>{roundBalance(singleUser.balance)}</span>
                  <span className='body_list'>{singleUser.user.details}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
