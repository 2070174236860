import React from 'react';

import Header from '../../../../Components/Header';
import { PlayerReportSearch } from '../../../../Components/Admin/Users/ReportSearch';
import { FooterMenu } from '../../../../Components/FooterMenu';

export const ManagerReportSearch = () => {
  return (
    <div className='agent_layout'>
      <Header name={'Agent Report'} />
      <PlayerReportSearch />
      <FooterMenu isManager={true}/>
    </div>
  );
};
