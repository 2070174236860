import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './TrialStart.scss';
import { GamePicker } from '../../../Components/GamePicker';
import Header from '../../../Components/Header';

const TrialStart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='trial_home page_container'>
      <Header logo={true} name={t('trial.title')} login={true}></Header>
      <div className='page_content'>
        <GamePicker />
        <button className='secondary' onClick={() => navigate('/')}>{t('trial.backToWebsite')}</button>
      </div>
    </div>
  );
};

export default TrialStart;
