import { Button } from '@material-ui/core';
import React from 'react';

import './FirstLoginMessage.scss';

const FirstLoginMessage =  (props) => {
  return (
    <div className={'center_dialog_box first_login_message'}>
      <span className='dialog_header'>Agent Information</span>
      <span className='dialog_subheader'>Agent_name</span>

      <span className='text_center'>
        Baccarat Min Bet : 20 Max Bet : 5000<br/>
        Sic Bo Min Bet : 5 Max Bet : 5000<br/>
        Roulette Min Bet: 5 Max Bet: 5000<br/>
      </span>
      <span className='slider-text'>
        This page is for agent to provide player the agent contact and
        transfer information
      </span>
      <span className='slider-text'>Contact: 1723494040</span>
      <span className='slider-text'>Bank account</span>

      <span className='slider-text'>Minimum transfer 500</span>
      <span className='slider-text'>
        The agent information appear when the player first login.
      </span>
      <div className='text_center'>
        <Button onClick={props.close}>Close</Button>
      </div>
    </div>
  );
};

export default FirstLoginMessage;
