import React from 'react';
import '@fontsource/inter';
import '@fortawesome/fontawesome-free/css/all.min.css';

import PageNotSpotted from '../../../Components/PageNotSpotted';
import { GameType } from '../../../Utils';
import './AppSicBo.scss';
import { GameEngine } from '../../../Components/GameEngine';

export const AppSicBo = () => {
  return (
    <>
      <GameEngine
        title='骰宝 Sic Bo'
        containerClass='homesicbo'
        gameType={GameType.SicBo}
      />
      <PageNotSpotted />
    </>
  );
};
