import React from 'redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './StartPages.scss';

interface props {
  isManager?: boolean;
}

export const StartPages = (props: props) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const baseUrl = props.isManager ? '/Manager' : '/Agent';

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <div className='pages_widget'>
      <button className="nav_button player"  onClick={() => redirect(baseUrl + '/Users')}>
        <span className="icon"></span>
        <span className="cn">{t('admin.members.cn')}</span>
        <span className="en">{t('admin.members.en')}</span>
      </button>
      <button className="nav_button add_player" onClick={() => redirect(baseUrl + '/Users/Add')}>
        <span className="icon"></span>
        <span className="cn">{t('admin.addMember.cn')}</span>
        <span className="en">{t('admin.addMember.en')}</span>
      </button>
      <button className="nav_button message" onClick={() => redirect(baseUrl + '/Messages')}>
        <span className="icon"></span>
        <span className="cn">{t('admin.messages.cn')}</span>
        <span className="en">{t('admin.messages.en')}</span>
      </button>
      <button className="nav_button transaction" onClick={() => redirect(baseUrl + '/Transactions')}>
        <span className="icon"></span>
        <span className="cn">{t('admin.transactions.cn')}</span>
        <span className="en">{t('admin.transactions.en')}</span>
      </button>
    </div>
  );
};
