import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import * as UserAction from '../../Store/Actions/User';
import Loader from '../Loader';

export const UserListOutlet = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // @ts-ignore
    UserAction.getUsers().then(() => {
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  }, []);

  return loading ? <Loader /> : error ? <div>Error while loading users</div> : <Outlet />;
};
