import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { Transaction, useGameReducer } from '../../Store';
import { DATE_FORMAT, TIME_FORMAT } from '../../environment.config';
import Header from '../Header';

export const TransactionDetails = () => {
  const { t } = useTranslation();
  const gameReducer = useGameReducer();
  const [ transaction, setTransaction] = useState<Transaction>();
  const { id } = useParams();

  useEffect(() => {
    setTransaction(gameReducer.creditTransactions.find(v => v.id === id));
  }, [gameReducer.creditTransactions, id]);

  return (
    <>
      <Header name={t(transaction?.details === 'Withdraw' ? 'transactions.withdrawCredit' : 'transactions.depositCredit')} agentMenu={true} />
      <div className='agent_body transaction_details'>
        {transaction && <>
          <div className="detail">
            <div className="logo">
              娱乐<span>60</span>秒 play<span>60</span>s
            </div>
            <div className="data">
              <div className="label">{t('transactions.vipRoom')}</div>
              <div className="value">{gameReducer.userDetail.name}</div>
            </div>
            <div className="data">
              <div className="label">{t('transactions.member')}</div>
              <div className="value">{transaction.correspondingUsername} {transaction.correspondingDisplayName && (`(${transaction.correspondingDisplayName})`) }</div>
            </div>
            <div className="data">
              <div className="label">{t('transactions.date')}</div>
              <div className="value">{moment(transaction.created).format(DATE_FORMAT)}</div>
            </div>
            <div className="data">
              <div className="label">{t('transactions.time')}</div>
              <div className="value">{moment(transaction.created).format(TIME_FORMAT)}</div>
            </div>
            {transaction?.details === 'Credit' &&
              <div className="change_value">{t('transactions.deposited')} {
                transaction.debitAmount !== '0'
                  ? ('+' + transaction.debitAmount)
                  : ('+' + transaction.creditAmount)
              }</div>
            }
            {transaction?.details === 'Withdraw' &&
              <div className="change_value">{t('transactions.withdrew')} {
                transaction.creditAmount !== '0'
                  ? ('-' + transaction.creditAmount)
                  : ('-' + transaction.debitAmount)
              }</div>
            }
          </div>
        </>}
      </div>
    </>
  );
};
