import axios from 'axios';
import moment from 'moment-timezone';
import { t } from 'i18next';

import { GameStep, gameSteps, StepId } from '../../Constants/gameSteps';
import {
  ALL_RESULT,
  ALL_SHOW_RESULT_AREA,
  ALL_SHOW_WINNING_AREA,
  BACCARAT_APPLY_CHIP,
  BACCARAT_BET_SELECTION,
  BACCARAT_BETS_BY_SESSION,
  BACCARAT_CLEAR_BET_SELECTION,
  BACCARAT_CONFIRMING_BET,
  BACCARAT_RESULT,
  BACCARAT_SHOW_RESULT_AREA,
  BACCARAT_SHOW_WINNING_AREA,
  CHANGE_TRIAL_BALANCE,
  ERROR,
  ERROR_PAGE,
  GET_GAME_STATISTICS,
  IN_PROGRESS,
  LOGOUT,
  RESET_GAME,
  ROULETTE_APPLY_CHIP,
  ROULETTE_BET_SELECTION,
  ROULETTE_BETS_BY_SESSION,
  ROULETTE_CLEAR_BET_SELECTION,
  ROULETTE_CONFIRMING_BET,
  ROULETTE_RESULT,
  ROULETTE_SHOW_RESULT_AREA,
  ROULETTE_SHOW_WINNING_AREA,
  SET_STEP,
  SICBO_APPLY_CHIP,
  SICBO_BET_SELECTION,
  SICBO_BETS_BY_SESSION,
  SICBO_CLEAR_BET_SELECTION,
  SICBO_CONFIRMING_BET,
  SICBO_RESULT,
  SICBO_SHOW_RESULT_AREA,
  SICBO_SHOW_WINNING_AREA
} from '../../Constants';
import { GameReducerState, GameSession, GameStatistics } from '../app.state';
import {
  clearAccessToken,
  GameType,
  getRequestConfig, playAudio,
  requestError,
  retryRequest,
  vibrate
} from '../../Utils';
import { getGameTime, getTopBets, getTopWinners, getUserBalanceAndMessages } from './User';
import { store } from '../store';


export const setStep = (step: GameStep) => {
  store.dispatch({ type: SET_STEP, stepId: step.id, message: step.message, stepTime: step.time });
};

export const nextStep = (nextStepId: StepId, gameReducer?: GameReducerState, gameType?: GameType, trial?: boolean, website?: boolean) => {
  if (website && nextStepId === StepId.GAME) { nextStepId = StepId.LATEST_RESULT; }
  const nextActiveStep = {...gameSteps.find((data) => data.id === nextStepId)};
  const additionalTime = 15;

  switch (nextStepId) {
    case StepId.LATEST_RESULT:
      getGameTime(true).then((session: GameSession) => {
        const currentTime = moment(new Date().valueOf() + session.timeDifference);
        const closedAt = moment(session.closedAt);
        const diff = closedAt.diff(currentTime, 'seconds');
        const firstPage = {...gameSteps.find((data) => data.id === StepId.LATEST_RESULT)};
        const loadingPage = {...gameSteps.find((data) => data.id === StepId.LOADING)};
        if (diff > 0) {
          firstPage.time = diff;
          setStep(firstPage);
        } else {
          loadingPage.time = diff + additionalTime;
          setStep(loadingPage);
        }
      });
      break;
    case StepId.GAME:
      resetGame();
      getGameTime().then((session: GameSession) => {
        const currentTime = moment(new Date().valueOf() + session.timeDifference);
        const closedAt = moment(session.closedAt);
        const diff = closedAt.diff(currentTime, 'seconds');
        const firstPage = {...gameSteps.find((data) => data.id === StepId.GAME)};
        const loadingPage = {...gameSteps.find((data) => data.id === StepId.LOADING)};
        if (diff > 0) {
          firstPage.time = diff;
          setStep(firstPage);
        } else {
          loadingPage.time = diff + additionalTime;
          setStep(loadingPage);
        }
      });
      break;

    case StepId.TOP_BETS:
      if (website) {
        resetGame();
      }
      playAudio('/GameSound/time-up.mp3');
      vibrate(250);
      setStep(nextActiveStep);
      clearBetSelection(gameType);
      if (!trial) {
        getTopBets(gameReducer.session.id, gameType);
      }
      break;

    case StepId.RESULT:
      getGameResult(gameReducer.session.id, gameType).then((res: any) => {
        vibrate(250);
        const baccaratRes = res.results.baccarat;
        const baccaratCardLength = baccaratRes.bankerCards?.length + baccaratRes.playerCards?.length;
        switch (gameType) {
          case GameType.Baccarat:
            if (baccaratCardLength === 6) {
              nextActiveStep.time = 17;
            } else if (baccaratCardLength === 5) {
              nextActiveStep.time = 14;
            } else {
              nextActiveStep.time = 9;
            }
            break;
          case GameType.SicBo:
          case GameType.Roulette:
            nextActiveStep.time = 14;
            break;
          case null:
            nextActiveStep.time = baccaratCardLength === 6 ? 17 : 14;
        }
        setStep(nextActiveStep);
      });
      break;

    case StepId.HIGHLIGHT_BETS:
      store.dispatch({type: getResultAreaActionType(gameType)});
      switch (gameType) {
        case GameType.Baccarat:
          nextActiveStep.message = t(`baccaratResult.${gameReducer.baccaratResult}.mixed`);
          break;
        case GameType.Roulette:
          nextActiveStep.message = gameReducer.rouletteResult;
          break;
        case GameType.SicBo:
          store.dispatch({type: SICBO_SHOW_RESULT_AREA});
          const total = gameReducer.sicboResult[0] + gameReducer.sicboResult[1] + gameReducer.sicboResult[2];
          nextActiveStep.message = `${gameReducer.sicboResult[0]} + ${gameReducer.sicboResult[1]} + ${gameReducer.sicboResult[2]} = ${total}`;
          break;
      }

      setStep(nextActiveStep); // TODO baccaratResult
      break;

    case StepId.TOP_WINNERS:
      if (!trial) {
        getTopWinners(gameReducer.session.id); // TODO wire it
      }
      nextActiveStep.time = 1;
      setStep(nextActiveStep);
      break;

    case StepId.HIGHLIGHT_WINNER_BETS:
      store.dispatch({type: getWinningAreaActionType(gameType)}); // TODO: remove it TOP_WINNERS step is added back

      if (gameReducer.winningChips > 0) {
        playAudio('/GameSound/chip.mp3');
      }
      setStep(nextActiveStep);
      break;

    case StepId.LOADING:
    case StepId.OFFLINE:
    case StepId.ERROR:
      setStep(nextActiveStep);
      break;

    default:
      break;
  }
};

const confirmBetsSuccess = (gameType: GameType, trial: boolean, bets?: number) => {
  let confirmActionType: string;
  switch (gameType) {
    case GameType.Baccarat:
      confirmActionType = BACCARAT_CONFIRMING_BET;
      break;
    case GameType.SicBo:
      confirmActionType = SICBO_CONFIRMING_BET;
      break;
    case GameType.Roulette:
      confirmActionType = ROULETTE_CONFIRMING_BET;
      break;
  }
  store.dispatch({type: confirmActionType});
  loadBalance(trial, trial ? bets * -1 : null);
  playAudio('/GameSound/chip.mp3');
};

export const confirmBets = async (trial: boolean, userId: string, sessionId: string, gameType: GameType, gameState) => new Promise((resolve, reject) => {
  store.dispatch({ type: IN_PROGRESS });
  const bets = [];
  gameState.filter((data) => {
    if (data.activeChip !== null) {
      bets.push({
        userId,
        gameSessionId: sessionId,
        gameType: gameType,
        fieldName: data.backEndValue,
        amount: data.activeChip.toString(),
      });
    }
  });
  if (!trial) {
    axios(getRequestConfig('/bookmaker/bets', {bets}, 'POST'))
      .then((response: any) => {
        if (!response.data.failedBets || response.data.failedBets.length === 0) {
          confirmBetsSuccess(gameType, false);
          resolve(true);
        } else {
          store.dispatch({
            type: ERROR,
            data: { error_msg: response.data.error[0] },
          });
          reject(ERROR);
        }
      })
      .catch((error) => {
        if (error && error.response) {
          clearBetSelection(gameType);
          if (error.response.status === 403) {
            clearAccessToken();
            store.dispatch({
              type: LOGOUT,
            });
          } else
            store.dispatch({
              type: ERROR,
              data: { error_msg: error.response.data.message },
            });
        } else {
          store.dispatch({
            type: ERROR,
            data: { error_msg: error.message.toString() },
          });
        }
        reject(ERROR);
      });
  } else {
    confirmBetsSuccess(gameType, true, gameState.filter(c => c.activeChip).reduce((acc, cur) => acc + cur.activeChip, 0));
    resolve({
      status: 200,
      data: {failedBets: []}
    });
  }
});

export const resetGame = () => {
  store.dispatch({
    type: RESET_GAME
  });
};

export const loadBalance = (trial: boolean, changeAmount?: number) => {
  if (!trial) {
    getUserBalanceAndMessages();
  } else {
    store.dispatch({type: CHANGE_TRIAL_BALANCE, amount: changeAmount});
  }
};

export const clearBetSelection = (gameType: GameType) => {
  store.dispatch({ type: getClearBetActionType(gameType) });
};

const getClearBetActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_CLEAR_BET_SELECTION;
    case GameType.Roulette: return ROULETTE_CLEAR_BET_SELECTION;
    case GameType.SicBo: return SICBO_CLEAR_BET_SELECTION;
    default: return null;
  }
};


export const betSelection = (gameType: GameType, selectedBet, selectedChip: string) => {
  if (selectedChip && selectedChip !== '0') {
    store.dispatch({
      type: getBetSelectionActionType(gameType),
      data: selectedBet,
    });
    vibrate(50);
  }
};

const getBetSelectionActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_BET_SELECTION;
    case GameType.Roulette: return ROULETTE_BET_SELECTION;
    case GameType.SicBo: return SICBO_BET_SELECTION;
    default: return null;
  }
};

export const applyChip = (selectedChip, gameType: GameType) => {
  let actionType: string;
  switch (gameType) {
    case GameType.Baccarat:
      actionType = BACCARAT_APPLY_CHIP;
      break;
    case GameType.SicBo:
      actionType = SICBO_APPLY_CHIP;
      break;
    case GameType.Roulette:
      actionType = ROULETTE_APPLY_CHIP;
      break;
  }

  store.dispatch({
    type: actionType,
    data: selectedChip,
  });
};

const getBetsSuccess = (bets: any, gameType: GameType, trial: boolean) => {
  let actionType: string;
  switch (gameType) {
    case GameType.Baccarat:
      actionType = BACCARAT_BETS_BY_SESSION;
      break;
    case GameType.SicBo:
      actionType = SICBO_BETS_BY_SESSION;
      break;
    case GameType.Roulette:
      actionType = ROULETTE_BETS_BY_SESSION;
      break;
  }
  if (!trial) {
    getUserBalanceAndMessages();
  }
  playAudio('/GameSound/chip.mp3');
  store.dispatch({type: actionType, data: bets});
};

export const getBetsBySession = (userId: string, sessionId: string, gameType: GameType, trial: boolean) => new Promise((resolve, reject) => {
  store.dispatch({ type: IN_PROGRESS });
  const path = '/bookmaker/bets?userId=' + userId + '&gameSessionId=' + sessionId;
  if (!trial) {
    retryRequest(getRequestConfig(path), t('error.betFetch'))
      .then((response) => {
        if (response.status === 200) {
          let bets = [];
          if (response.data.bets.length > 0 && sessionId === response.data.bets[0].gameSessionId) {
            bets = response.data.bets;
          }
          getBetsSuccess(bets, gameType, trial);
          resolve(bets);
        }
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 403) {
            clearAccessToken();
            store.dispatch({
              type: LOGOUT,
            });
          } else
            store.dispatch({
              type: ERROR_PAGE,
              // data: { error_msg: error.response.data.message },
            });
        } else {
          store.dispatch({
            type: ERROR,
            data: {error_msg: error.message.toString()},
          });
        }
        reject(ERROR);
      });
  } else {
    getBetsSuccess([], gameType, trial);
    resolve([]);
  }
});

export const getGameResult = (sessionId, gameType: GameType) => new Promise((resolve, reject) => {
  store.dispatch({ type: IN_PROGRESS });
  gameResult(getRequestConfig('/bookmaker/games?sessionIds=' + sessionId), sessionId)
    .then((resultSession) => {
      store.dispatch({
        type: getResultActionType(gameType),
        data: resultSession,
      });
      resolve(resultSession);
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 403) {
          clearAccessToken();
          store.dispatch({
            type: LOGOUT,
          });
        } else
          store.dispatch({
            type: ERROR,
            data: { error_msg: error.response.data.message },
          });
      } else {
        store.dispatch({
          type: ERROR_PAGE,
        });
      }
      reject(ERROR);
    });
});

const gameResult = async (api, sessionId, count = 0) => {
  try {
    if (count < 5) {
      const response = await axios(api);
      const resultSession = response.data.sessions.find((result) => result.id === sessionId && result?.results?.roulette !== null);
      if (!resultSession) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return await gameResult(api, sessionId, ++count);
      }
      return resultSession;
    } else {
      throw Error('method is called more than 3 times');
    }
  } catch (error) {
    throw error;
  }
};

const getResultActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_RESULT;
    case GameType.Roulette: return ROULETTE_RESULT;
    case GameType.SicBo: return SICBO_RESULT;
    default: return ALL_RESULT;
  }
};

const getWinningAreaActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_SHOW_WINNING_AREA;
    case GameType.Roulette: return ROULETTE_SHOW_WINNING_AREA;
    case GameType.SicBo: return SICBO_SHOW_WINNING_AREA;
    default: return ALL_SHOW_WINNING_AREA;
  }
};

const getResultAreaActionType = (gameType: GameType) => {
  switch (gameType) {
    case GameType.Baccarat: return BACCARAT_SHOW_RESULT_AREA;
    case GameType.Roulette: return ROULETTE_SHOW_RESULT_AREA;
    case GameType.SicBo: return SICBO_SHOW_RESULT_AREA;
    default: return ALL_SHOW_RESULT_AREA;
  }
};


export const getGameStatistics = (): Promise<GameStatistics> => new Promise((resolve, reject) => {
  store.dispatch({ type: IN_PROGRESS });
  axios(getRequestConfig('/bookmaker/games/statistics'))
    .then((statistics) => {
      store.dispatch({
        type: GET_GAME_STATISTICS,
        data: statistics.data,
      });
      resolve(statistics.data);
    })
    .catch((error) => {
      requestError(store.dispatch, null)(error);
      reject(ERROR);
    });
});
