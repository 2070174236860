import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import './TransactionRecord.scss';
import Header from '../../../Components/Header';
import Loader from '../../../Components/Loader';
import { DATE_FORMAT, TIME_FORMAT } from '../../../environment.config';
import { useGameReducer } from '../../../Store';
import { PAGINATION_LIMIT } from '../../../Constants';
import { UserType } from '../../../Utils';
import { UserActions } from '../../../Store/Actions';

const USER_PAGINATION_LIMIT = 10;

export const TransactionRecord = () => {
  const { t } = useTranslation();
  const reducer = useGameReducer();
  const [lastDate, setLastDate] = useState(null);

  useEffect(() => {
    UserActions.resetUserTransactions();
    setLastDate(null);
    fetchTransactions();
  }, []);

  useEffect(() => {
    setLastDate(reducer.creditTransactions?.length > 0
      ? reducer.creditTransactions[reducer.creditTransactions.length - 1].created
      : null);
  }, [reducer.creditTransactions]);

  const fetchTransactions = () => {
    const limit = reducer.userDetail?.role !== UserType.PLAYER ? PAGINATION_LIMIT : USER_PAGINATION_LIMIT;
    UserActions.getUserTransactions(lastDate, limit);
  };

  return (
    <div className='page_container transaction_record'>
      <Header name={t('menu.transactions')} />
      <div className='page_content full'>
        { reducer.userDetail?.role === UserType.PLAYER && (
          <div className='list_label'>{t('transactions.lastNTransactions', {count: USER_PAGINATION_LIMIT})}</div>
        )}
        <div className='bet_history_main list'>
          <div className='history_header list-header'>
            <div className='history_header_inner'>
              <span className='header_inner_text'>{t('transactions.date')}</span>
              <span className='header_inner_text'>{t('transactions.time')}</span>
              <span className='header_inner_text'>{t('transactions.type')}</span>
              <span className='header_inner_text'>{t('transactions.amount')}</span>
            </div>
          </div>

          <div className='bet_history_body list-body'>
            {reducer.creditTransactions?.sort((a, b) => a.created > b.created ? -1 : 1).map(transaction => {
              const date = moment(transaction.created);
              const amount = Number(transaction.creditAmount) || Number(transaction.debitAmount) * -1;
              return (
                <div className='history_body' key={transaction.id}>
                  <div className='history_body_inner'>
                    <span className='body_inner_text'>{date.format(DATE_FORMAT)}</span>
                    <span className='body_inner_text'>{date.format(TIME_FORMAT) } </span>
                    <span className='body_inner_text'>{amount > 0 ? t('transactions.deposited') : t('transactions.withdrew') }</span>
                    <span className='body_inner_text'>{amount > 0 ? '+' : ''}{amount}</span>
                  </div>
                </div>
              );
            })}
            { reducer.loading && <Loader></Loader> }
            { reducer.creditTransactions.length > 0 && !reducer.loading && reducer.creditTransactionsHasMore && (
              <button className='more' onClick={fetchTransactions}>{t('label.loadMore')}</button>
            ) }
          </div>
        </div>
      </div>
    </div>
  );
};
