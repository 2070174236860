import { useTranslation } from 'react-i18next';
import React  from 'redux';
import { useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import './Header.scss';
import { APP_BASE_PATH } from '../../Constants';
import { ChangeLanguageDialog } from '../dialogs/ChangeLanguage';
import { ChangeLanguageButton } from '../dialogs/ChangeLanguage/ChangeLanguageButton';
import { Play60s } from '../LuckyMinute/LuckyMinute';
import { PayoutInfo } from '../dialogs/PayoutInfo';
import { GameType } from '../../Utils';
import { UserActions } from '../../Store/Actions';

interface props {
  logo?: boolean;
  backToHome?: boolean;
  backToUrl?: string;
  leftMenu?: boolean;
  name?: string;
  agentMenu?: boolean;
  menu?: boolean;
  login?: boolean;
  background?: string;
  gameType?: GameType;
}

const Header = (props: props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [languageSelector, setLanguageSelector] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const redirect = (url) => {
    navigate(url);
  };

  const logout = () => {
    UserActions.logout();
  };

  return (
    <div className={'header bg_'+props.background}>

      {props.login && (<button className='login_btn' onClick={() => redirect(APP_BASE_PATH)}>{t('label.login')}</button>)}
      {!props.login && props.leftMenu && (
        <div className="left-menu">
          <Dropdown className='main_menu'>
            <Dropdown.Toggle id='dropdown-basic'>
              <i className='fa-solid fa-bars game_info'></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => redirect(APP_BASE_PATH)}>
                {t('label.login')}</Dropdown.Item>
              <Dropdown.Item onClick={() => setLanguageSelector(true)}>
                {t('menu.language')} <span>{i18n.language == 'en' ? '中文' : 'ENG'}</span></Dropdown.Item>
              <Dropdown.Item onClick={() => redirect('/')}>
                {t('menu.statistics')}</Dropdown.Item>
              <Dropdown.Item onClick={() => redirect('/')}>
                {t('menu.pastResults')}</Dropdown.Item>
              <Dropdown.Item onClick={() => redirect('/')}>
                {t('menu.gameGuides')}</Dropdown.Item>
              <Dropdown.Item onClick={() => redirect('/')}>
                {t('menu.platform')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {!props.login && !props.leftMenu && props.logo && (
        <div className='header-button'>
          <img className='back_btn' src='/logo192.png' alt='Logo' />
        </div>
      )}
      {!props.login && !props.leftMenu && !props.logo && (
        <div className='header-button' onClick={() => navigate((props.backToHome ? APP_BASE_PATH : (props.backToUrl || -1) as To))}>
          <i className='fa-solid fa-angle-left back_btn'></i>
        </div>
      )}
      {props.name === 'Play60s' && (<Play60s />)}
      {props.name !== 'Play60s' && (
        <span className='user_name'>{props.name}</span>
      )}
      {props.login && (<ChangeLanguageButton narrow={true} />)}

      {props.agentMenu && (
        <Dropdown className='main_menu'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i className='fa-solid fa-ellipsis-vertical game_info'></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setLanguageSelector(true)}>
              {t('menu.language')} <span>{i18n.language == 'en' ? '中文' : 'ENG'}</span></Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>
              {t('label.logout')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {props.menu && (
        <Dropdown className='main_menu'>
          <Dropdown.Toggle id='dropdown-basic'>
            <i className='fa-solid fa-ellipsis-vertical game_info'></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              {t('menu.agentInfo')}</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/TransactionRecord`)}>
              {t('menu.transactions')}</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/BetHistory`)}>
              {t('menu.betHistory')}</Dropdown.Item>
            <Dropdown.Item onClick={() => setLanguageSelector(true)}>
              {t('menu.language')} <span>{i18n.language == 'en' ? '中文' : 'ENG'}</span></Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/ChangePassword`)}>
              {t('menu.changePass')}</Dropdown.Item>
            <Dropdown.Item onClick={() => redirect(`${APP_BASE_PATH}/SoundVibrate`)}>
              {t('menu.sound')}</Dropdown.Item>
            <Dropdown.Item onClick={() => logout()}>
              {t('label.logout')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      { props.gameType && !props.menu && !props.agentMenu && !props.login && (
        <div className='header-button payout' onClick={() => setShowInfo(true)}>
          {t('payout_table')}
        </div>
      )}
      { showInfo && (
        <PayoutInfo gameType={props.gameType} close={() => setShowInfo(false)} />
      )}

      {languageSelector && (
        <ChangeLanguageDialog callback={() => {setLanguageSelector(false);}} />
      )}
    </div>
  );
};
export default Header;
