import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import './HomeApp.scss';
import Loader from '../../../Components/Loader';
import Notification from '../../../Components/Notification';
import { useGameReducer } from '../../../Store';
import { UserActions } from '../../../Store/Actions';

export const HomeApp = () => {
  const navigate = useNavigate();

  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.token) {
      UserActions.getUserDetails();
    } else {
      navigate('/Login');
    }
  }, [gameReducer.token]);

  return (
    <>
      <Notification />
      <Outlet />
      {gameReducer.loading && <Loader />}
    </>
  );
};
