import React from 'react';

import Header from '../../../Components/Header';
import './Transaction.scss';

import { useTranslation } from 'react-i18next';
import { FooterMenu } from '../../../Components/FooterMenu';
import { TransactionList } from '../../../Components/Admin/TransactionList';

const ManagerTransaction = () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.transactions')} agentMenu={true} />
      <TransactionList />
      <FooterMenu isManager={true}/>
    </div>
  );
};

export default ManagerTransaction;
