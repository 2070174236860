import React from 'react';

import './BaccaratResultDisplay.scss';

interface Props {
  winner: 'player' | 'banker' | 'tie';
}

const BaccaratResultDisplay = (props: Props) => {
  return (
    <div className='baccarat_result dialog'>
      <div></div>
      {props.winner === 'banker' && (
        <div className='result_inner banker'>
          <span>庄</span>
          <span>Banker</span>
        </div>
      )}
      {props.winner === 'player' && (
        <div className='result_inner player'>
          <span>闲</span>
          <span>Player</span>
        </div>
      )}
      {props.winner === 'tie' && (
        <div className='result_inner tie'>
          <span>和</span>
          <span>Tie</span>
        </div>
      )}
    </div>
  );
};

export default BaccaratResultDisplay;
