import React, { useEffect } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useGameReducer } from '../../Store';

const Notification =  (props) => {
  const gameReducer = useGameReducer();

  const notificationSetting: ToastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    if (gameReducer.error_msg) {
      const errors = gameReducer.error_msg.split('E:');
      const message = errors.length > 1 ? errors[0] : '';
      toast.error(message, notificationSetting);
    }
  }, [gameReducer.error_msg]);

  useEffect(() => {
    if (gameReducer.success_msg) {
      toast.success(gameReducer.success_msg, notificationSetting);
    }
  }, [gameReducer.success_msg]);

  useEffect(() => {
    if (props.internalMsg && props.internalMsg.message) {
      toast.error(props.internalMsg.message, notificationSetting);
    }
  }, [props.internalMsg]);

  return <ToastContainer />;
};

export default Notification;
