import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react'; 
import { t } from 'i18next';

import Header from '../Header';
import PageNotSpotted from '../PageNotSpotted';
import './ChangePassword.scss';
import { useGameReducer } from '../../Store';
import { UserActions } from '../../Store/Actions';

const defaultForm = {
  new_password: '',
  new_password2: '',
  old_password: '',
};

export const ChangePassword = () => {
  const reducer = useGameReducer();

  const [formData, setFormData] = useState(defaultForm);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFormData(defaultForm);
  }, [reducer.success_msg]);

  const handleChange = (event) => {
    const field = event.target.name;
    setFormData({...formData, [field]: event.target.value});
  };

  const submitForm = () => {
    if (!formData.old_password || !formData.new_password) {
      setError(t('changePass.required'));
    } else if (formData.new_password !== formData.new_password2) {
      setError(t('changePass.notMatch'));
    } else {
      setError(null);
      UserActions.changePassword(
        reducer.userDetail.name,
        formData.new_password,
        formData.old_password,
      );
    }
  };

  return (
    <div>
      <Header name={t('menu.changePass')} />
      <div className='reset-password'>
        <div className='reset-password_inner'>
          <div className='reset-password_info'>
            <span className='password_text'>{t('label.importantNotice')}</span>
            <span className='info_text'>
              {t('changePass.info1')}
              <br />
              {t('changePass.info2')}
              <br />
              {t('changePass.info3')}
            </span>
          </div>
        </div>

        <div className='reset-password-div'>
          <input
            id='old_pass'
            type='password'
            name='old_password'
            className='input_box'
            placeholder={t('changePass.oldPass')}
            value={formData.old_password}
            onChange={handleChange}
          />
          <input
            id='new_pass'
            type='password'
            name='new_password'
            className='input_box'
            placeholder={t('changePass.newPass')}
            value={formData.new_password}
            onChange={handleChange}
          />
          <input
            id='new_pass2'
            type='password'
            name='new_password2'
            className='input_box'
            placeholder={t('changePass.confirmPass')}
            value={formData.new_password2}
            onChange={handleChange}
          />
          { (reducer.success_msg) && (
            <div className='message success'>{error || t('changePass.success')}</div>
          )}
          { (error || reducer.error_msg) && (
            <div className='message error'>{error || t('changePass.failure')}</div>
          )}
          <Button variant='contained' className='login_btn' onClick={submitForm}>
            {t('label.apply')}
          </Button>
        </div>
      </div>
      <PageNotSpotted />
    </div>
  );
};
