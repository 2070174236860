import React from 'react';
import { useTranslation } from 'react-i18next';

import './PlayerBetHistory.scss';
import Header from '../../../Components/Header';
import { BetHistory } from '../../../Components/BetHistory';

export const PlayerBetHistory = () => {
  const { t } = useTranslation();

  return (
    <div className='page_container bet_history'>
      <Header name={t('menu.betHistory')} />
      <div className='page_content full'>
        <BetHistory />
      </div>
    </div>
  );
};
