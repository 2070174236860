import React from 'react';

import './PlayingCards.scss';

interface Props {
  suit: string;
  rank: string;
}

const PlayingCards = (props: Props) => {
  return (
    <div className='playing_cards'>
      <div className={`card_icon ${props.suit}`}></div>
      <div className={`numbers ${props.suit}`}>{props.rank}</div>
    </div>
  );
};

export default PlayingCards;
