import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import { UserDetail } from '../../../../Components/Admin/Users';
import { FooterMenu } from '../../../../Components/FooterMenu';

export const ManagerUserDetail = () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.memberProfile')} agentMenu={true} backToUrl={'/Manager/Users'} />
      <UserDetail isAgent={true} />
      <FooterMenu isManager={true}/>
    </div>
  );
};
