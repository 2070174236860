import React from 'react';

import './PageNotFound.scss';

export const PageNotFound = () => {
  return (
    <div className='pagenotfound'>
      <div className='pagenotfound_image'>
        <img src='/img/404.png' alt='404' />
      </div>
      <span className='pagenotfound_text'>Page Not Found</span>
    </div>
  );
};
