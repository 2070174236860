import React from 'react';
import { useTranslation } from 'react-i18next'; 

import Header from '../../../Components/Header';
import './AgentMessages.scss';
import { FooterMenu } from '../../../Components/FooterMenu';
import { MessageDetails } from '../../../Components/Admin/MessageDetails';

const AgentMessageDetails =  () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout message_details'>
      <Header name={t('menu.message')} agentMenu={true}  />

      <div className='agent_body'>
        <MessageDetails />
      </div>
      <FooterMenu />
    </div>
  );
};

export default AgentMessageDetails;
