import React from 'react';

import './LandingPage.scss';
import { GameEngine } from '../../../Components/GameEngine';

const LandingPage = () => {
  return (
    <>
      <GameEngine
        title={'Play60s'}
        containerClass={'landing_page'}
        gameType={null}
        trial={true}
        noBet={true}
        login={true}
        website={true}
      />
    </>
  );
};

export default LandingPage;
