import React from 'react';
import { useTranslation } from 'react-i18next'; 

import Header from '../../../Components/Header';
import './AgentMessages.scss';
import { FooterMenu } from '../../../Components/FooterMenu';
import { MessageNew } from '../../../Components/Admin/MessageNew';

const AgentMessageDetails =  () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout new_'>
      <Header name={t('messages.new')} agentMenu={true} />
      <div className='agent_body'>
        <MessageNew />
      </div>
      <FooterMenu />
    </div>
  );
};

export default AgentMessageDetails;
