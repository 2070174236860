import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer } from 'react-router-redux';
import promise from 'redux-promise';

import initialState from './initialState';
import gameReducer from './game.reducer';

const rootReducer = combineReducers({
  gameReducer,
  routing: routerReducer,
});

const composeEnhancers = composeWithDevTools({});

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, promise))
  // composeEnhancers(applyMiddleware(thunk, promise, logger))
);
