import React from 'react';
import { useTranslation } from 'react-i18next';

import './AgentMessages.scss';
import Header from '../../../Components/Header';
import { FooterMenu } from '../../../Components/FooterMenu';
import { MessageList } from '../../../Components/Admin/MessageList';

const AgentMessageList =  () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('userAction.messages')} agentMenu={true} />
      <div className="agent_body">
        <MessageList />
      </div>
      <FooterMenu />
    </div>
  );
};

export default AgentMessageList;
