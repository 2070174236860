import React from 'react'; 
import { useTranslation } from 'react-i18next';

import { FooterMenu } from '../../../Components/FooterMenu';
import Header from '../../../Components/Header';
import './Transaction.scss';
import { TransactionList } from '../../../Components/Admin/TransactionList';

const AgentTransaction =  () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.transactions')} agentMenu={true} />
      <TransactionList />
      <FooterMenu/>
    </div>
  );
};

export default AgentTransaction;
