import { useEffect, useState } from 'react';
import React from 'redux';
import moment from 'moment-timezone';

import { DATE_FORMAT, TIME_FORMAT } from '../../environment.config';
import { useGameReducer } from '../../Store';
import './StartBalance.scss';
import { UserActions } from '../../Store/Actions';

export const StartBalance = () => {
  const [amount, setAmount] = useState(0);
  const gameReducer = useGameReducer();

  useEffect(() => {
    if (gameReducer.userDetail) {
      UserActions.getUserBalanceAndMessages();
      UserActions.getUserTransactions(null, 1);
    }
  }, [gameReducer.userDetail]);

  useEffect(() => {
    if (gameReducer.balance) {
      setAmount(gameReducer.balance);
    }
  }, [gameReducer.balance]);

  const hasTransaction = gameReducer.creditTransactions && gameReducer.creditTransactions[0];
  const transactionDate = hasTransaction ? moment(gameReducer.creditTransactions[0].created) : null;
  const transactionAmount = hasTransaction ? (Number(gameReducer.creditTransactions[0].creditAmount) || Number(gameReducer.creditTransactions[0].debitAmount) * -1) : 0;


  return (
    <div className='balance_widget'>
      <div className='user_balance'>
        <span className='balance_icon'></span>
        <span className='balance_text'>{amount}</span>
      </div>
      <div className='user_info_inner'>
        {hasTransaction && (<>
          <div className='user_detail'>
            {/*<span className='detail_text'>{transactionAmount > 0 ? 'Deposited' : 'Withdraw' }</span>*/}
            <span className='detail_text'>{transactionAmount > 0 ? '+' : ''}{transactionAmount}</span>
          </div>
          <div className='user_detail date'>
            <span className='detail_text'>{transactionDate.format(DATE_FORMAT)}</span>
            <span className='detail_text'>{transactionDate.format(TIME_FORMAT)}</span>
          </div>
        </>)}
        {!hasTransaction && (
          <>
            <div className='user_detail'>
              <span className='detail_text'>无交易记录</span>
            </div>
            <div className='user_detail'>
              <span className='detail_text'>No transaction yet</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
