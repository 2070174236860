import React from 'react'; 

import './Transaction.scss';
import { TransactionDetails } from '../../../Components/Admin/TransactionDetails';
import { FooterMenu } from '../../../Components/FooterMenu';

const AgentTransactionDetails = () => {
  return (
    <div className='agent_layout'>
      <TransactionDetails />
      <FooterMenu />
    </div>
  );
};

export default AgentTransactionDetails;
