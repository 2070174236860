import React, { useState, useEffect } from 'react';
import Card from '../PlayingCards'; 

import './BaccaratBetting.scss';
import ChipsOnField from '../../ChipsOnField';
import { GameType, playAudio } from '../../../Utils';
import { useGameReducer } from '../../../Store';
import { StepId } from '../../../Constants/gameSteps';
import BaccaratResultDisplay from '../BaccaratResultDisplay';
import { GameActions } from '../../../Store/Actions';

const BaccaratBetting =  () => {
  const [resultRevealTime, setResultRevealTime] = useState(14);
  const [minutes, setMinutes] = useState(0.5);
  const [bankerTotal, setBankerTotal] = useState(null);
  const [playerTotal, setPlayerTotal] = useState(null);
  const [playerTotalAnimation, setPlayerTotalAnimation] = useState(false);
  const [bankerTotalAnimation, setBankerTotalAnimation] = useState(false);
  const [playerFirstCard, setPlayerFirstCard] = useState(false);
  const [playerSecondCard, setPlayerSecondCard] = useState(false);
  const [playerThirdCard, setPlayerThirdCard] = useState(false);
  const [bankerFirstCard, setBankerFirstCard] = useState(false);
  const [bankerSecondCard, setBankerSecondCard] = useState(false);
  const [bankerThirdCard, setBankerThirdCard] = useState(false);

  const cardDealAudio = '/GameSound/card-deal.mp3';

  const gameReducer = useGameReducer();

  useEffect(() => {
    setPlayerTotalAnimation(true);
    const timeout = setTimeout(() => {
      setPlayerTotalAnimation(false);
    }, 150);
    return () => clearTimeout(timeout);
  }, [playerTotal]);

  useEffect(() => {
    setBankerTotalAnimation(true);
    const timeout = setTimeout(() => {
      setBankerTotalAnimation(false);
    }, 150);
    return () => clearTimeout(timeout);
  }, [bankerTotal]);

  useEffect(() => {
    if (gameReducer.baccaratGameResult === null) {
      setBankerFirstCard(false);
      setBankerSecondCard(false);
      setBankerThirdCard(false);
      setPlayerFirstCard(false);
      setPlayerSecondCard(false);
      setPlayerThirdCard(false);
      setBankerTotal(null);
      setPlayerTotal(null);
    }
  }, [gameReducer.baccaratGameResult]);

  useEffect(() => {
    const hasFlippedCard = [bankerFirstCard, bankerSecondCard, bankerThirdCard, playerFirstCard, playerSecondCard, playerThirdCard].find(a => a === true);
    if (hasFlippedCard) {
      playAudio('/GameSound/card-flip.mp3');
    }
  }, [bankerFirstCard, bankerSecondCard, bankerThirdCard, playerFirstCard, playerSecondCard, playerThirdCard]);

  useEffect(() => {
    if (gameReducer.baccaratResult && gameReducer.baccaratGameResult && minutes > 0) {
      const timer = setInterval(() => setMinutes(minutes + 0.5), 500);
      if ([0.5, 2.5, 4.5, 6.5].find(v => v === minutes)) {
        playAudio(cardDealAudio);
      }
      if ((gameReducer.baccaratGameResult.playerCards.length === 3 || gameReducer.baccaratGameResult.bankerCards.length === 3) && minutes === 9.5) {
        playAudio(cardDealAudio);
      }
      if (gameReducer.baccaratGameResult.playerCards.length === 3 && gameReducer.baccaratGameResult.bankerCards.length === 3 && minutes === 12.5) {
        playAudio(cardDealAudio);
      }
      if (minutes > 0.5) {
        setPlayerTotal(gameReducer.playerTotal[0].toString());
      }
      if (minutes > 2.5) {
        setBankerTotal(gameReducer.bankerTotal[0].toString());
      }
      if (minutes > 4.5) {
        setPlayerTotal(gameReducer.playerTotal[1].toString());
      }
      if (minutes > 6.5) {
        setBankerTotal(gameReducer.bankerTotal[1].toString());
      }
      if (minutes > 9.5) {
        if (gameReducer.playerTotal.length > 2) {
          setPlayerTotal(gameReducer.playerTotal[2].toString());
        } else if (
          gameReducer.playerTotal.length < 3 &&
          gameReducer.bankerTotal.length > 2
        ) {
          setBankerTotal(gameReducer.bankerTotal[2].toString());
        }
      }

      if (minutes > 12.5) {
        if (gameReducer.bankerTotal.length > 2) {
          setBankerTotal(gameReducer.bankerTotal[2].toString());
        }
      }

      setResultRevealTime(gameReducer.baccaratCardLength === 6 ? 13 : gameReducer.baccaratCardLength === 5 ? 10 : 7);
      return () => clearInterval(timer);
    } else {
      setMinutes(0.5);
    }
  }, [minutes, gameReducer.baccaratResult]);

  useEffect(() => {
    if (gameReducer.showWinningArea) {
      setMinutes(20);
    }
  }, [gameReducer.showWinningArea]);

  const returnCardDesign = (isActive, card) => {
    return (
      <Card rank={card.figure} suit={card.suit} />
    );
  };
  const handleClickRow = (selectedBet) => {
    if (!gameReducer.disableBet) {
      GameActions.betSelection(GameType.Baccarat, selectedBet, gameReducer.selectedChip);
    }
  };

  return (
    <div className='main_layout'>
      <div className='main_layout_inner'>
        <div className='baccaratbetting'>
          <div className='baccaratbetting_cards'>
            <div className='cards_inner'>
              <div className='card_text player'>
                <div className="card_labels">
                  <span>闲</span>
                  <span>Player</span>
                </div>
                <span className={'result_number ' + (playerTotalAnimation === true ? 'animation': '')}>
                  {playerTotal ? playerTotal : ''}</span>
              </div>
              {gameReducer.baccaratResult !== null && (
                <div className='all_card player'>
                  {(minutes > 9) &&
                    gameReducer.baccaratGameResult?.playerCards.length > 2 &&
                    returnCardDesign(
                      playerThirdCard,
                      gameReducer.baccaratGameResult?.playerCards[2]
                    )}

                  {(minutes > 4) && returnCardDesign(
                    playerSecondCard,
                    gameReducer.baccaratGameResult?.playerCards[1]
                  )}

                  {minutes > 0 && returnCardDesign(
                    playerFirstCard,
                    gameReducer.baccaratGameResult?.playerCards[0]
                  )}

                </div>
              )}
            </div>
            <div className='cards_inner'>
              <div className='card_text banker'>
                <span className={'result_number ' + (bankerTotalAnimation === true ? 'animation' : '')}>
                  {bankerTotal ? bankerTotal : ''}</span>
                <div className="card_labels">
                  <span>庄</span>
                  <span>Banker</span>
                </div>
              </div>
              {gameReducer.baccaratResult !== null && (
                <div className='all_card banker'>
                  {(minutes > 2) && returnCardDesign(
                    bankerFirstCard,
                    gameReducer.baccaratGameResult?.bankerCards[0]
                  )}
                  {(minutes > 6) && returnCardDesign(
                    bankerSecondCard,
                    gameReducer.baccaratGameResult?.bankerCards[1]
                  )}

                  {((minutes > 9 &&
                    gameReducer.baccaratGameResult?.playerCards.length < 3) ||
                    minutes > 12) &&
                    gameReducer.baccaratGameResult?.bankerCards.length > 2 &&
                    returnCardDesign(
                      bankerThirdCard,
                      gameReducer.baccaratGameResult?.bankerCards[2]
                    )}
                </div>
              )}
            </div>
          </div>

          <div className='baccaratbetting_bets_row2'>
            {gameReducer.baccarat.map(
              (data, index) =>
                data.index === 1 && (
                  <div
                    key={index}
                    className={`bets2_inner ${
                      data.isActive ? 'selected-bet' : ''
                    }`}
                    onClick={() => handleClickRow(data)}
                  >
                    <span className={`bets2_text ${data.color} ${data.isActive}`}>
                      {data.chinese}
                    </span>
                    <span className={`bets2_text ${data.color} ${data.isActive}`}>
                      {data.english}
                    </span>
                    <span className={`banker_win_text ${data.color} ${data.isActive}`}>
                      {data.value}
                    </span>
                    <ChipsOnField large={true} data={data}></ChipsOnField>
                  </div>
                )
            )}
          </div>

          <div className='baccaratbetting_bets_row3'>
            {gameReducer.baccarat.map(
              (data, index) =>
                data.index === 2 && (
                  <div
                    key={index}
                    className={`bets_inner ${
                      data.isActive ? 'selected-bet' : ''
                    }`}
                    onClick={() => handleClickRow(data)}
                  >
                    <span className={`bets_text ${data.color} ${data.isActive}`}>
                      {data.chinese}
                    </span>
                    <span className={`bets_text ${data.color} ${data.isActive}`}>
                      {data.english}
                    </span>
                    <span className={`bets_text odds ${data.color} ${data.isActive}`}>
                      {data.value}
                    </span>
                    <ChipsOnField large={true} data={data}></ChipsOnField>
                  </div>
                )
            )}
          </div>
        </div>

        {gameReducer.stepId === StepId.RESULT && minutes > resultRevealTime
          && (<BaccaratResultDisplay winner={gameReducer.baccaratResult} />)}
      </div>
    </div>
  );
};

export default BaccaratBetting;
