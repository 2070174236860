import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 

import Loader from '../../../Components/Loader';
import PageNotSpotted from '../../../Components/PageNotSpotted';
import './Start.scss';
import Header from '../../../Components/Header';
import FirstLoginMessage from '../../../Components/dialogs/FirstLoginMessage';
import { useGameReducer } from '../../../Store';
import { GamePicker } from '../../../Components/GamePicker';
import { StartBalance, StartMessages, StartUserInfo } from '../../../Components/StartWidgets';
import { UserActions } from '../../../Store/Actions';

export const Start =  () => {
  const navigate = useNavigate();
  const [firstMessage, setFirstMessage] = useState(false);
  let timeout;

  const gameReducer = useGameReducer();

  useEffect(() => {
    UserActions.getUserTransactions();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (gameReducer.token == null) {
      navigate('/Login');
    } else {
      UserActions.getUserDetails();
    }
  }, [gameReducer.token]);

  return (
    <>
      {firstMessage && (<FirstLoginMessage close={() => setFirstMessage(false)} />) }
      <div className='start page_container'>
        <Header logo={true} name='Play60s' menu={true}></Header>
        <div className='start_inner page_content'>
          <StartUserInfo />
          <StartBalance />
          <StartMessages />
          <GamePicker />
        </div>

        {gameReducer.loading && <Loader />}
      </div>
      <PageNotSpotted />
    </>
  );
};
