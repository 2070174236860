import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../../Components/Header';
import { FooterMenu } from '../../../../Components/FooterMenu';
import { UserList } from '../../../../Components/Admin/Users';

export const AgentUsersList = () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('menu.members')} agentMenu={true} backToHome={true} />
      <UserList isAgent={true} />
      <FooterMenu />
    </div>
  );
};
